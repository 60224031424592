import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../loading";
import ClientResults from "./ClientResults";
import { HeaderLogo } from "../HeaderLogo";

export const ClientContext = createContext({
  isClient: false,
});

const ClientResultsContainer = () => {
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [hairAnalysis, setHairAnalysis] = useState({});
  const [gender, setGender] = useState("M");

  const { patient_id, hair_analysis_id } = useParams();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/patient/${patient_id}/hair_analysis/${hair_analysis_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response, "response")
        if (response.success) {
          setFirstName(response.patient?.firstName);
          setLastName(response.patient?.lastName);
          setHairAnalysis(response.hair_analysis);
          setGender(response.patient?.gender);
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
      })
      .finally(() => {
        setReady(true);
      });
  }, [patient_id]);

  return (
    <>
      <main className={`mx-auto w-full flex flex-col ${ready ? "" : "hidden"}`}>
        <HeaderLogo />
        {message && <p className="mb-8 text-center text-red-600">{message}</p>}
              <ClientResults
                handleTriggerRelaod={() => {}}
                analysis={hairAnalysis}
                base_analysis={hairAnalysis}
                firstName={firstName}
                lastName={lastName}
                clientGender={gender}
              />
      </main>
      <Loading message={"Loading your results"} ready={ready} />
    </>
  );
};

export default ClientResultsContainer;
