import React, { useEffect, useState } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom";
import GroIndexGraph from "./GroIndexGraph";
import ImageLoader from "./ImageLoader";
import ShowHeadAvatar from "./ShowHeadAvatar";
import { hairThinningUiData } from "./barGraphData";

const ClientResults = ({
  analysis,
  base_analysis,
  handleTriggerRelaod,
  firstName,
  lastName,
  clientGender,
}) => {
  const location = useLocation();
  const {  hair_analysis_id, mainType, subType } = useParams();
  const [overlayImage, setOverlayImage] = useState(null);
  const [hairThinningData, setHairThinningData] = useState({});

  useEffect(() => {
    const secondaryImage = analysis?.images?.closeup?.ml?.[subType];
    if (!secondaryImage) {
      setOverlayImage(null);
    } else if (secondaryImage?.length) {
      const requiredImage = secondaryImage?.find((img) => img?.paint === 7);
      setOverlayImage(requiredImage);
    } else {
      setOverlayImage(analysis?.images?.closeup?.ml?.[subType]);
    }

    const thinningItem = Object.values(hairThinningUiData).find((level) =>
      mainType === "closeup"
        ? level.markerKey ===
          analysis?.images?.[mainType].hair_thinning?.[subType]?.label
        : level.markerKey === analysis?.images?.[mainType].hair_thinning?.label
    );
    setHairThinningData(thinningItem);
  }, [analysis, location]);

  const shouldShowHairThinningData = (hairThinningData && analysis?.images?.[mainType]?.raw?.[subType])

  const getHairthinningData = () => {
    if(hairThinningData?.romanValue) return "Class " + hairThinningData?.romanValue + " hair loss";
    return "Your hair is healthy"
  }


  return (
    <>
      <div className="px-4 sm:px-14">
        <h1
          className="mb-1 sm:mb-3 text-center font-bold text-text-3 text-opacity-85 text-lg leading-5 tracking-widest"
          style={{ letterSpacing: "1.6px" }}
        >
          {`${firstName?.replace(
            firstName[0],
            firstName[0]?.toUpperCase()
          )} ${lastName?.replace(lastName[0], lastName[0]?.toUpperCase())}`}
        </h1>
      </div>
      {!analysis?.images?.[mainType]?.raw?.[subType] && (
					<p className="mt-5 xs:mt-1 block h-44 xs:h-56 sm:hidden text-center text-gray-400">No image found</p>
			)}
      <div style={{ flexGrow: 1 }} className="md:mt-20">
        <div className="lg:mx-15 xl:mx-20 grid grid-cols-12 sm:grid-cols-3 gap-4 sm:gap-5 jusitfy-center px-4 sm:px-0">
          <div className="w-full col-span-5 sm:col-span-1 pt-6 sm:pt-7">
            {base_analysis &&
              analysis?.images?.[mainType]?.raw?.[subType]?.status !==
                "invalid" && (
                <GroIndexGraph
                  graphType={"hair_thinning"}
                  analysis={analysis}
                  base_analysis={base_analysis}
                  forType={subType}
                  mainType={mainType}
                  hideGrowthIndex={true}
                />
              )}
          </div>

          <div className="w-full col-span-7 sm:col-span-1 flex justify-center align-center">
            {analysis?.images?.[mainType].raw && (
              <ImageLoader
                img={analysis?.images?.[mainType]?.raw?.[subType]}
                secondaryImg={
                  mainType === "global"
                    ? subType === "crown"
                      ? analysis?.images?.global?.ml?.[subType]
                      : ""
                    : overlayImage
                }
                onDoubleClick={() => {}}
              />
            )}
          </div>

          <div className="col-span-12 block sm:hidden text-center text-text-3 tracking-widest mt-5 mb-7">
            <p className={`text-3xl opacity-90 capitalize leading-relaxed ${!shouldShowHairThinningData && 'invisible'}`}>
              {shouldShowHairThinningData ? getHairthinningData() : "No hair thinning data available"}
            </p>
            <p className={`text-2xl font-light opacity-80 ${!shouldShowHairThinningData && "invisible"}`}>
              {shouldShowHairThinningData ? hairThinningData?.tolltipDescription?.slice(1, -1) : "No percentage data available"}
            </p>
          </div>

          <div className="col-span-2 block sm:hidden"></div>

          <div className="w-full col-span-8 sm:col-span-1 flex justify-evenly flex-row">
            <ShowHeadAvatar
              handleTriggerRelaod={() => {}}
              analysis={analysis}
              showHead={["left", "right"].includes(subType) ? "left" : "top"}
              forType={subType}
              mainType={mainType}
              clientGender={clientGender}
            />
          </div>

          <div className="col-span-2 block sm:hidden"></div>

          <div className="col-span-3 hidden sm:block text-center text-text-3 tracking-widest my-6 sm:my-2">
          <p className={`text-3xl opacity-90 capitalize leading-relaxed ${!shouldShowHairThinningData && 'invisible'}`}>
              {shouldShowHairThinningData ? getHairthinningData() : "No hair thinning data available"}
            </p>
            <p className={`text-2xl font-light opacity-80 ${(!shouldShowHairThinningData || !hairThinningData?.tolltipDescription) && "invisible"}`}>
              {(shouldShowHairThinningData && hairThinningData?.tolltipDescription) ? hairThinningData?.tolltipDescription?.slice(1, -1) : "No percentage data available"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientResults;
