import React, { useState } from 'react';
import MaleHeadTop from "../../assets/images/MaleHeadTop.png";
import MaleHeadLeft from "../../assets/images/MaleHeadLeft.png";
import MaleHeadRight from "../../assets/images/MaleHeadRight.png";
import FemaleHeadTop from "../../assets/images/FemaleHeadTop.png";
import FemaleHeadLeft from "../../assets/images/FemaleHeadLeft.png";
import FemaleHeadRight from "../../assets/images/FemaleHeadRight.png";
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";

import DotGrey from "../../assets/images/DotGrey.svg";
import DotTurquoise from "../../assets/images/DotTurquoise.svg";
import { Link, useParams, useNavigate } from 'react-router-dom';

const heads = [
	'left',
	'top',
	'right',
]

const ShowHeadAvatar = ({ showHead, mainType, analysis, forType, handleTriggerRelaod, clientGender }) => {
	const { patient_id, hair_analysis_id, subType, graphType } = useParams();
	const [currentHead, setCurrentHead] = useState(showHead);

	const history = useNavigate();

	const gender = clientGender;

	return (
		<div className="flex items-center justify-center w-full">
			<button
				className="font-bold text-2xl focus:outline-none"
				onClick={function () {
					const index = heads.findIndex(r => r === currentHead);
					if (index > 0) {
						setCurrentHead(heads[index - 1]);
						history(`/results/${patient_id}/${hair_analysis_id}/global/${heads[index - 1] === 'top' ? 'crown' : heads[index - 1] }`)
					}
				}}
			>
				<img src={lessThanIcon} className="h-6 w-6" />
			</button>
			{
				currentHead === 'top' &&
				<div className='relative '>
					<Link to={`/results/${patient_id}/${hair_analysis_id}/global/crown`}>
						{
							gender === 'F' ? <img src={FemaleHeadTop} alt='femaleHead' className="w-36 sm:w-48 px-2" /> : <img src={MaleHeadTop} alt='maleHead' className="w-36 sm:w-48 px-2" />
						}
					</Link>
					<div className='absolute top-0 left-1/2 flex flex-col items-center justify-around h-full pb-8' style={{ transform: 'translate(-50%, 0)' }}>
						<div>
							<ShowDot mainType={mainType} forType="vertex" subType={subType} link={`/results/${patient_id}/${hair_analysis_id}/closeup/vertex`} />
						</div>
						<div>
							<ShowDot mainType={mainType} forType="crown" subType={subType} link={`/results/${patient_id}/${hair_analysis_id}/closeup/crown`} />
						</div>
						<div>
							<ShowDot mainType={mainType} forType="front" subType={subType} link={`/results/${patient_id}/${hair_analysis_id}/closeup/front`} />
						</div>
					</div>
				</div>
			}

			{
				currentHead === 'left' &&
				<div className='relative '>
					<Link to={`/results/${patient_id}/${hair_analysis_id}/global/left`}>
						{
							gender === 'F' ? <img src={FemaleHeadLeft} className="w-46 sm:w-56 px-2" /> : <img src={MaleHeadLeft} className="w-36 sm:w-48 px-2" />
						}
					</Link>

					<div className='absolute top-0 left-1/2 flex flex-col items-center justify-around h-full pb-8' style={{ transform: 'translate(-50%, 0)' }}>
						<div>
							<ShowDot mainType={mainType} forType="left" subType={subType} link={`/results/${patient_id}/${hair_analysis_id}/closeup/left`} />
						</div>
						<div>
						</div>
						<div>
						</div>
					</div>
				</div>
			}

			{
				currentHead === 'right' &&
				<div className='relative '>
					<Link to={`/results/${patient_id}/${hair_analysis_id}/global/right`}>
						{
							gender === 'F' ? <img src={FemaleHeadRight} className="w-46 sm:w-56 px-2" /> : <img src={MaleHeadRight} className="w-36 sm:w-48 px-2" />
						}
					</Link>

					<div className='absolute top-0 left-1/2 flex flex-col items-center justify-around h-full pb-8' style={{ transform: 'translate(-50%, 0)' }}>
						<div>
							<ShowDot mainType={mainType} forType="right" subType={subType} link={`/results/${patient_id}/${hair_analysis_id}/closeup/right`} />
						</div>
						<div>
						</div>
						<div>
						</div>
					</div>
				</div>
			}
			<button
				className="font-bold text-2xl focus:outline-none"
				onClick={function () {
					const index = heads.findIndex(r => r === currentHead);
					if (index + 1 < heads.length) {
						setCurrentHead(heads[index + 1]);
						history(`/results/${patient_id}/${hair_analysis_id}/global/${heads[index + 1] === 'top' ? 'crown' : heads[index + 1]}`);
					}
				}}
			>
				<img src={greaterThanIcon} className="h-6 w-6" />
			</button>
		</div>
	)
}

const ShowDot = ({ forType, subType, mainType, link }) => {


	if (forType === subType && mainType === 'closeup') {
		return (
				<Link to={link} className="h-3 w-3 sm:h-4 sm:w-4">
					<img src={DotTurquoise} className='h-3 w-3 sm:h-4 sm:w-4 cursor-pointer'/>
				</Link>
		)
	}
	return <Link className="h-3 w-3 sm:h-4 sm:w-4" to={link}><img src={DotGrey} className='h-3 w-3 sm:h-4 sm:w-4 cursor-pointer'/></Link>
};

export default React.memo(ShowHeadAvatar)