import React, { useCallback, useLayoutEffect, useEffect, useState } from 'react'
import ShopifyBuy from '@shopify/buy-button-js';
import { useLocation } from "react-router-dom";
import LessThanIcon from "../assets/images/lessThanIcon.svg";
import GreaterThanIcon from "../assets/images/greaterThanIcon.svg";
import KerafactorProductImage from "../assets/products/kerafactor-product.jpg"
import { AiOutlineInfoCircle } from "react-icons/ai"
import GroCamera from "../assets/images/GroTrackCamera.png"

// data
import productDescriptions from "../data/productDescriptions.json";

// utils
import { isExactMatch } from "../utils/index"

// constants
import { PRODUCTS } from "../constants/products"

// hooks
import useMediaQuery from '../hooks/useMediaQuery';
import { useClinic } from '../hooks/useClinic';

// shopify clients
const zenagenShopifyClient = ShopifyBuy.buildClient({
    domain: 'shop-zenagen.myshopify.com',
    storefrontAccessToken: '734540683c4d0dfb47ebe455d39f7bf1',
});
const zenagenUi = ShopifyBuy.UI.init(zenagenShopifyClient);

const hairmaxShopifyClient = ShopifyBuy.buildClient({
    domain: 'tryhairmax.myshopify.com',
    storefrontAccessToken: '241d1db1ebea919969faf41e49ada412',
});
const hairmaxUi = ShopifyBuy.UI.init(hairmaxShopifyClient);

const actiivClient = ShopifyBuy.buildClient({
    domain: 'actiivbrand.myshopify.com',
    storefrontAccessToken: '807c3565c4ba228a476a335a59f1fd52',
});
const actiivUI = ShopifyBuy.UI.init(actiivClient);

// Local constants
const HAIRMAX_PRODUCTS_CONTAINER_ID = "hairmax-products-container"
const ZENAGEN_PRODUCTS_CONTAINER_ID_ONE = 'zenagen-products-container-one'
const ZENAGEN_PRODUCTS_CONTAINER_ID_TWO = 'zenagen-products-container-two';
const ACTIIV_PRODUCTS_CONTAINER_ID = 'actiiv-products-container';

const ShopifyProductCollection = ({ clientGender = "", clinicProducts, products = [] }) => {

    const [customProducts, setCustomProducts] = useState([]);
    const { clinicName, renderClinicProductResults } = useClinic()


    const handleChangeTogglePosition = useCallback(() => {
        setTimeout(() => {
            const toggleButtons = document.querySelectorAll(".shopify-buy-frame.shopify-buy-frame--toggle.is-sticky.is-active")
            toggleButtons?.forEach((button, index) => {
                const length = toggleButtons.length;
                const height = button.offsetHeight;
                const windowHeight = window.innerHeight;
                const top = (windowHeight / 2) - (height * length / 2) + (height * (index * 1.1));
                button.style.top = `${top}px`;
            })
        }, 100)
    }, [])

    const getDefaultEmbedOptions = useCallback((containerId, prefixDollar = true) => {
        return {
            options: {
                product: {
                    order: [
                        'img',
                        'imgWithCarousel',
                        'title',
                        'variantTitle',
                        'price',
                        'quantity',
                        'options',
                        'button',
                        'buttonWithQuantity',
                        'description',
                    ],
                    contents: {
                        img: true,
                        imgWithCarousel: false,
                        title: true,
                        variantTitle: false,
                        price: true,
                        unitPrice: true,
                        options: true,
                        quantity: false,
                        quantityIncrement: false,
                        quantityDecrement: false,
                        quantityInput: true,
                        button: true,
                        buttonWithQuantity: false,
                        description: true,
                    },
                    templates: {
                        price: `<div class="{{data.classes.product.prices}}" data-element="product.prices">
                      {{#data.selectedVariant}}
                      <span class="visuallyhidden">{{data.priceAccessibilityLabel}}&nbsp;</span>
                      <span class="{{data.classes.product.variantTitle}}" data-element="product.variantTitle">Special Price</span>
                      <span class="{{data.classes.product.price}} {{data.priceClass}}" data-element="product.price">${prefixDollar ? `$` : ``}{{data.formattedPrice}}</span>
                      {{#data.hasCompareAtPrice}}
                      <span class="visuallyhidden">{{data.compareAtPriceAccessibilityLabel}}&nbsp;</span>
                      <span class="{{data.classes.product.compareAt}}" data-element="product.compareAt">{{data.formattedCompareAtPrice}}</span>
                      {{/data.hasCompareAtPrice}}
                      {{#data.showUnitPrice}}
                      <div class="{{data.classes.product.unitPrice}}" data-element="product.unitPrice">
                        <span class="visuallyhidden">{{data.text.unitPriceAccessibilityLabel}}</span>
                        {{data.formattedUnitPrice}}<span aria-hidden="true">/</span><span class="visuallyhidden">&nbsp;{{data.text.unitPriceAccessibilitySeparator}}&nbsp;</span>{{data.formattedUnitPriceBaseUnit}}
                      </div>
                      {{/data.showUnitPrice}}
                      {{/data.selectedVariant}}
                    </div>`,
                        button: `<div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper">
                              <button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button">
                                {{data.buttonText}}
                                <svg xmlns="http://www.w3.org/10000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="white" d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
                              </button>
                              <div class="{{data.classes.product.infoToggleWrapper}}">
                                More Info
                                <svg class="{{data.classes.product.infoToggler}} {{data.classes.product.flipIcon}}" fill="#ffffff" height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/10000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-300.3 -300.3 930.60 930.60" xml:space="preserve" stroke="#ffffff" stroke-width="33">
                                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                                  <rect x="-300.3" y="-300.3" width="930.60" height="930.60" rx="465.3" fill="#0495af" strokewidth="0"/>
                                  </g>
                                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="2.64"/>
                                  <g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/> </g>
                                </svg>
                              </div>
                            </div>`,
                        description: '<div class="{{data.classes.product.description}} {{data.classes.product.hidden}}" data-element="product.description">{{{data.descriptionHtml}}}</div>'
                    },
                    classes: {
                        infoToggleWrapper: "info-toggle-wrapper",
                        infoToggler: "info-toggler",
                        flipIcon: "flipped-icon",
                        hidden: "hidden"
                    },
                    styles: {
                        product: {
                            "@media (min-width: 100px)": {
                                "margin": "0px",
                                "margin-bottom": "50px",
                                "width": "100%"
                            },
                            "img": {
                                "height": "90%",
                                "position": "absolute",
                                "left": "50%",
                                "top": "50%",
                                "transform": "translate(-50%, -50%)",
                            },
                            "imgWrapper": {
                                "padding-top": "100%",
                                "position": "relative",
                                "height": "0",
                                "margin-bottom": "15px",
                                "background-color": "#FFF"
                            },
                            "title": {
                                "font-size": "25px",
                                "font-weight": "bold",
                                "color": "white"
                            },
                            "price": {
                                "font-weight": "700",
                                "font-style": "italic",
                                "font-size": "18px",
                                color: "silver"
                            },
                            "buttonWrapper": {
                                "margin": "25px auto 0px auto",
                                "display": "flex",
                                "justify-content": "space-between",
                                "align-items": "center",
                                "gap": "20px"
                            },
                            "button": {
                                "background-color": "#0495af",
                                "color": "white",
                                "padding": "8px 22px",
                                "font-size": "18px",
                                "display": "flex",
                                "align-items": "center",
                                "gap": "10px",
                                "border-radius": "8px",
                                "margin": "0px",
                                ":hover": {
                                    "background-color": "#0495af"
                                }
                            },
                            "description": {
                                "font-size": "18px",
                                "background-color": "#0495af",
                                "line-height": "22px",
                                "padding": "16px 24px",
                                "font-weight": "normal",
                                "color": "#efefef",
                            },
                            "variantTitle": {
                                "font-size": "17px",
                                "font-weight": "bold",
                                "color": "silver"

                            },
                            "infoToggleWrapper": {
                                "font-size": "18px",
                                "display": "flex",
                                "align-items": "center",
                                "gap": "10px",
                                "color": "white"
                            },
                            "infoToggler": {
                                "cursor": "pointer",
                            },
                            "flipIcon": {
                                "transform": " rotate(180deg)"
                            },
                            "hidden": {
                                "display": "none"
                            },
                            "compareAt": {
                                "color": "#FFF",
                                "fontSize": "14px"
                            }

                        }
                    },
                    text: {
                        "button": "Add to cart"
                    },
                    DOMEvents: {
                        'click .info-toggler': function (e, target) {
                            // toggle description visibility
                            const productDescription = target?.parentNode?.parentNode?.nextSibling;
                            target?.classList?.toggle("flipped-icon");
                            productDescription?.classList?.toggle("hidden");

                            // // update iframe height
                            const iframe = document?.getElementById(containerId)?.querySelector('iframe');
                            const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;
                            const height = (innerDoc?.querySelector(".shopify-buy__collection") || innerDoc?.querySelector(".shopify-buy__product"))?.offsetHeight;
                            if (iframe) iframe.style.height = `${height}px`
                        },
                    },
                    events: {
                        addVariantToCart: handleChangeTogglePosition,
                    }
                },
                productSet: {
                    "styles": {
                        "products": {
                            "@media (min-width: 101px)": {
                                // "width": "100%"
                                margin: "0px"

                            }
                        }
                    }
                },
                modalProduct: {
                    "contents": {
                        "img": false,
                        "imgWithCarousel": true,
                        "button": false,
                        "buttonWithQuantity": true
                    },
                    "styles": {
                        "product": {
                            "@media (min-width: 101px)": {
                                "max-width": "100%",
                                "width": "100%",
                                "margin-bottom": "0px"
                            }
                        }
                    },
                    "text": {
                        "button": "Add to cart"
                    }
                },
                option: {},
                cart: {
                    "text": {
                        "total": "Subtotal",
                        "button": "Checkout"
                    }
                },
            },
        }
    }, [handleChangeTogglePosition])

    useLayoutEffect(() => {
        if (
            !clinicProducts?.includes(PRODUCTS.ZENAGEN) || 
            !document.getElementById(ZENAGEN_PRODUCTS_CONTAINER_ID_ONE)
        ) return;

        zenagenUi?.createComponent('collection', {
            id: '421304729840',
            node: document.getElementById(ZENAGEN_PRODUCTS_CONTAINER_ID_ONE),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            ...getDefaultEmbedOptions(ZENAGEN_PRODUCTS_CONTAINER_ID_ONE, false)
        }).then(() => {
            setTimeout(() => {
                handleChangeTogglePosition()
                const iframe = document?.getElementById(ZENAGEN_PRODUCTS_CONTAINER_ID_ONE)?.querySelector('iframe');
                const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;

                const productTitles = [...(innerDoc?.querySelectorAll('[data-element="product.title"]'))];

                // Find the first and second product elements
                const firstProductElement = productTitles?.[1]?.closest(".shopify-buy__product");
                const secondProductElement = productTitles?.[2]?.closest(".shopify-buy__product");

                if (clientGender === "male" && firstProductElement && secondProductElement) {
                    // Get the parent element of the products
                    const parentElement = firstProductElement.parentElement;

                    // Insert the second product before the first product to switch their order
                    parentElement.insertBefore(secondProductElement, firstProductElement);
                }


                productTitles?.forEach(title => {
                    const isMaleProduct = isExactMatch(" men", title?.innerText?.toLowerCase());
                    const isFemaleProduct = isExactMatch("women", title?.innerText?.toLowerCase());

                    const product = title?.closest(".shopify-buy__product");

                    if (clientGender === "male" && isFemaleProduct && product) product.style.display = "none";
                    if (clientGender === "female" && isMaleProduct && product) product.style.display = "none";

                    const productDescription = product?.querySelector('[data-element="product.description"]');
                    if (productDescription && productDescriptions[title?.innerText]) productDescription.innerHTML = productDescriptions[title?.innerText];
                })

                const height = innerDoc?.querySelector('.shopify-buy__collection')?.offsetHeight;
                if (iframe) iframe.style.height = `${height}px`;

            }, 100)
        });
    }, [clientGender, clinicProducts, getDefaultEmbedOptions, handleChangeTogglePosition])

    useLayoutEffect(() => {
        if (
            !clinicProducts?.includes(PRODUCTS.ZENAGEN) || 
            !document.getElementById(ZENAGEN_PRODUCTS_CONTAINER_ID_TWO)
        ) return;

        zenagenUi?.createComponent('collection', {
            id: '424326660336',
            node: document.getElementById(ZENAGEN_PRODUCTS_CONTAINER_ID_TWO),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            ...getDefaultEmbedOptions(ZENAGEN_PRODUCTS_CONTAINER_ID_TWO, false)

        }).then(() => {
            setTimeout(() => {

                handleChangeTogglePosition()

                const iframe = document?.getElementById(ZENAGEN_PRODUCTS_CONTAINER_ID_TWO)?.querySelector('iframe')
                const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;

                const productTitles = [...(innerDoc?.querySelectorAll('[data-element="product.title"]'))];

                productTitles?.forEach(title => {
                    const isMaleProduct = isExactMatch(" men", title?.innerText?.toLowerCase());
                    const isFemaleProduct = isExactMatch("women", title?.innerText?.toLowerCase());

                    const product = title?.closest(".shopify-buy__product");

                    if (clientGender === "male" && isFemaleProduct && product) product.style.display = "none";
                    if (clientGender === "female" && isMaleProduct && product) product.style.display = "none";

                })
               
                const height = innerDoc?.querySelector('.shopify-buy__collection')?.offsetHeight;
                if (iframe) iframe.style.height = `${height}px`;

            }, 100)

        });
    }, [clientGender, clinicProducts, getDefaultEmbedOptions, handleChangeTogglePosition])

    useLayoutEffect(() => {
        if (
            !clinicProducts?.includes(PRODUCTS.ACTIIV) || 
            !document.getElementById(ACTIIV_PRODUCTS_CONTAINER_ID)
        ) return;

        actiivUI?.createComponent('collection', {
            id: '424804253933',
            node: document.getElementById(ACTIIV_PRODUCTS_CONTAINER_ID),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            ...getDefaultEmbedOptions(ACTIIV_PRODUCTS_CONTAINER_ID, false)

        }).then(() => {
            setTimeout(() => {

                handleChangeTogglePosition()

                const iframe = document?.getElementById(ACTIIV_PRODUCTS_CONTAINER_ID)?.querySelector('iframe')
                const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;

                let productTitles = [...(innerDoc?.querySelectorAll('[data-element="product.title"]'))];

                productTitles?.forEach(title => {
                    
                    const isMaleProduct = isExactMatch(" men", title?.innerText?.toLowerCase());
                    const isFemaleProduct = isExactMatch("women", title?.innerText?.toLowerCase());

                    const product = title?.closest(".shopify-buy__product");

                    if (clientGender === "male" && isFemaleProduct && product) product?.remove()
                    if (clientGender === "female" && isMaleProduct && product) product?.remove()

                    const shouldUpdateProductPrice = title?.innerText === "RECOVER WOMEN'S BUNDLE" || title?.innerText === "RECOVER MEN'S BUNDLE";
                    if(shouldUpdateProductPrice) {
                        const productComparePrice = product?.querySelector('[data-element="product.compareAt"]');
                        if(productComparePrice) productComparePrice.innerText = '$147'
                    } 

                })

                productTitles = [...(innerDoc?.querySelectorAll('[data-element="product.title"]'))];

                const parentElement = productTitles?.[0]?.closest(".shopify-buy__product")?.parentNode;
                const firstChild = parentElement?.firstElementChild;
                parentElement?.removeChild(firstChild);
                parentElement?.appendChild(firstChild);

                const height = innerDoc?.querySelector('.shopify-buy__collection')?.offsetHeight;
                if (iframe) iframe.style.height = `${height}px`;

            }, 100)
        });
    }, [clientGender, clinicProducts, getDefaultEmbedOptions, handleChangeTogglePosition])

    useLayoutEffect(() => {
        if (
            !clinicProducts?.includes(PRODUCTS.HAIRMAX) || 
            !document.getElementById(HAIRMAX_PRODUCTS_CONTAINER_ID)
        ) return;
        if (Boolean(document.getElementById(HAIRMAX_PRODUCTS_CONTAINER_ID)?.firstChild)) return;

        hairmaxUi.createComponent('product', {
            id: '4635653111926',
            node: document.getElementById(HAIRMAX_PRODUCTS_CONTAINER_ID),
            moneyFormat: '%7B%7Bamount%7D%7D',
            ...getDefaultEmbedOptions(HAIRMAX_PRODUCTS_CONTAINER_ID)
        }).then(() => {
            setTimeout(() => {

                handleChangeTogglePosition()

                const collectionParent = document.getElementById(HAIRMAX_PRODUCTS_CONTAINER_ID);
                if (collectionParent) collectionParent.style.maxWidth = "100%";

                const iframe = collectionParent?.querySelector('iframe');
                const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;

                const productTitles = [...(innerDoc?.querySelectorAll('[data-element="product.title"]'))];

                productTitles?.forEach(title => {
                    const isMaleProduct = isExactMatch(" men", title?.innerText?.toLowerCase());
                    const isFemaleProduct = isExactMatch("women", title?.innerText?.toLowerCase());

                    const product = title?.closest(".shopify-buy__product");

                    if (clientGender === "male" && isFemaleProduct && product) product.style.display = "none";
                    if (clientGender === "female" && isMaleProduct && product) product.style.display = "none";

                    const productDescription = product?.querySelector('[data-element="product.description"]');
                    if (productDescription && productDescriptions[title?.innerText]) productDescription.innerHTML = productDescriptions[title?.innerText];
                })

                const height = innerDoc?.querySelector("body")?.offsetHeight;
                if (iframe) iframe.style.height = `${height}px`;

            }, 100)

        });
    }, [clientGender, clinicProducts, getDefaultEmbedOptions, handleChangeTogglePosition]);

    useLayoutEffect(() => {
        if (clinicProducts?.includes(PRODUCTS.KERAFACTOR)) {
            setCustomProducts(prev => [
                ...prev,
                {
                    img: KerafactorProductImage,
                    title: 'KeraFactor Combo System',
                    price: '$218',
                    link: 'https://shop.mykerafactor.com/products/kerafactor-solution-and-conditioner-combo',
                    description: 'Each highly concentrated 50ml bottle of KeraFactor Solution contains the proprietary KeraFactor Peptide Complex (KFP™). KFP is a unique formula that contains 7 Polypeptides that include both specifically selected growth factors and skin proteins to help with hair and scalp health. This patented complex helps to "turn on" weak or resting hair follicles and improve scalp circulation and nutrients to produce thicker, longer healthy-looking hair. In addition to the solution, each bottle contains a dropper applicator for specific and efficient delivery of the KFP to the scalp and surrounding follicles. Use 1 full dropper morning or night on dry or wet hair, massage onto scalp.'
                }
            ])
        }
    }, [clinicProducts])

    return (
        <div className="mt-10 mb-20 w-full flex flex-col">
            {clinicProducts?.includes(PRODUCTS.ZENAGEN)  && (
                <>
                    <div id={ZENAGEN_PRODUCTS_CONTAINER_ID_ONE} className="w-full px-5"></div>
                    <div id={ZENAGEN_PRODUCTS_CONTAINER_ID_TWO} className="w-full px-5"></div>
                </>
            )}
            {clinicProducts?.includes(PRODUCTS.HAIRMAX) && (
                <div id={HAIRMAX_PRODUCTS_CONTAINER_ID} className="w-full px-5"></div>
            )}
            {clinicProducts?.includes(PRODUCTS.ACTIIV) && (
                <div id={ACTIIV_PRODUCTS_CONTAINER_ID} className="w-full px-5"></div>
            )}
            <div className='w-full p-5'>
                {customProducts?.map((product, index) => (
                    <CustomProduct product={product} key={index} />
                ))}
            </div>
            {renderClinicProductResults && products?.length &&  (
                    <ProductResults
                        clientGender={clientGender}
                        products={products}
                        clinicName={clinicName}
                    />
            )}
        </div>
    )
}

export default ShopifyProductCollection


export const ProductResults = ({ products, clientGender, clinicName }) => {
    const matches = useMediaQuery('(max-width: 1026px)');
    const [requiredProduct, setRequiredProduct] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const product = products?.[0]
        setRequiredProduct(product);
    }, [products, location]);

    const results = requiredProduct?.results?.filter((item, index) => {
        return clientGender === "female" || clientGender === "other" ? [1, 2, 5].includes(index) : [0, 3, 4].includes(index)
    })

    const handleChangeIndex = (action) => {
        switch (action) {
            case "next":
                if (currentIndex >= (results?.length - 1)) return;
                setCurrentIndex((prev) => prev + 1);
                return;
            case "prev":
                if (currentIndex === 0) return;
                setCurrentIndex((prev) => prev - 1);
                return;
            default:
                return;
        }
    };

    return (
        <section>
            <main className="text-center flex flex-col gap-5 justify-evenly items-center w-full mx-auto text-white px-2">
                <h1 className="text-2xl uppercase tracking-normal text-white">Results</h1>
                <ResultsCarousel
                    results={results}
                    currentIndex={currentIndex}
                    handleChangeIndex={handleChangeIndex}
                />
                <div className='flex flex-col gap-2'>
                <h1 className="text-xl uppercase mt-12 my-3 tracking-normal">
                    MONEY BACK GUARANTEE
                </h1>
                <div className="flex flex-col">
                    <div className="flex pt-1 pb-3 ">
                        <img src={GroCamera} className="w-6/12" alt='gro-camera' />
                        <div
                            className="text-[12px] text-left font-bold"
                            style={{ fontFamily: "Roboto", lineHeight: "1.125rem" }}
                        >
                            We use {clinicName} to track your results. You will be fully refunded
                            if the products didn’t work for your hair loss.
                            <div className="relative group inline ml-1">
                                <AiOutlineInfoCircle className="w-5 inline text-button-5" />
                                <div className="hidden group-hover:flex absolute top-1/2 right-0 lg:top-8 lg:left-1/2 z-10 flex-col items-center" style={{ transform: matches ? 'translate(-20px, -50%)' : 'translate(-50%, -8px)' }}>
                                    <div className="px-3 py-2 text-xs lg:text-sm font-medium text-white bg-gray-100 rounded-lg shadow-sm w-auto" style={{ minWidth: '250px' }}>
                                        {!matches && <svg className="absolute text-gray-100 h-2 w-auto left-0 top-0" style={{ transform: "rotate(180deg) translate(0px, 8px)" }} x="0px" y="0px" viewBox="0 0 255 255"><polygon class="fill-current" points="0,0 127.5,127.5 255,0" /></svg>}
                                        You are required to purchase and use solutions for six months, and use GroTech to measure the results along the way.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </main>
        </section>
    );
};

const ResultsCarousel = ({ results = [], currentIndex, handleChangeIndex }) => {
    useEffect(() => {
        // Update carousel on props change
    }, [results, currentIndex]);
    return (
        <div className="w-full grid grid-cols-2 gap-1 border border-gray-400 relative">

            {/* images */}
            {results.map((imageSet, index) => {
                const isActiveItem = index === currentIndex;
                const beforeImageUrl = imageSet?.before_image?.image_path;
                const afterImageUrl = imageSet?.after_image?.image_path;
                return (
                    <React.Fragment key={index}>
                        <div className={`col-span-1 ${isActiveItem ? '' : 'hidden'} aspect-w-16 aspect-h-16 lg:aspect-h-14`}>
                            <img
                                alt="before-img"
                                src={beforeImageUrl}
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <div className={`col-span-1 ${isActiveItem ? '' : 'hidden'} aspect-w-16 aspect-h-14`}>
                            <img
                                alt="after-img"
                                src={afterImageUrl}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </React.Fragment>
                );
            })}

            {/* nav buttons */}
            <div
                className="absolute top-1/2 w-full flex justify-between"
                style={{ transform: "translate(0%, -50%)" }}
            >
                <button
                    className="bg-transparent p-0 cursor-pointer"
                    onClick={() => {
                        handleChangeIndex("prev");
                    }}
                >
                    <img alt="left-icon" src={LessThanIcon} className="h-10 w-10" />
                </button>
                <button
                    className="bg-transparent p-0 cursor-pointer"
                    onClick={() => {
                        handleChangeIndex("next");
                    }}
                >
                    <img alt="right-icon" src={GreaterThanIcon} className="w-10 h-10" />
                </button>
            </div>
        </div>
    );
};

export const CustomProduct = ({ product }) => {
    const [showDescription, setShowDescription] = useState(false)

    const handleToggleDescription = () => {
        setShowDescription(prev => !prev)
    }

    return (
        <div className='flex flex-col gap-2 mb-10'>
            <img className='w-full h-auto !pb-0 aspect-w-1 aspect-h-1' src={product?.img} alt={product?.title} />
            <h3 className='text-center text-white font-bold text-[25px]'>{product?.title}</h3>
            <p className='text-center text-[17px] font-bold text-[#c0c0c0]'>{product?.price}</p>
            <div className='flex justify-between items-center'>
                <a href={product?.link} target='_blank' className='bg-[#0495af] px-[22px] py-2 text-white text-[18px] rounded-lg outline-none border-none' rel="noreferrer">
                    Buy now
                </a>
                <div className='flex items-center gap-[10px] text-[18px] text-white'>
                    More info
                    <svg
                        onClick={handleToggleDescription}
                        class={`cursor-pointer ${showDescription ? 'rotate-0' : 'rotate-180'}`}
                        fill="#ffffff"
                        height="25px"
                        width="25px"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/10000/svg"
                        viewBox="-300.3 -300.3 930.60 930.60"
                        stroke="#ffffff"
                        stroke-width="33"
                    >
                        <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                            <rect x="-300.3" y="-300.3" width="930.60" height="930.60" rx="465.3" fill="#0495af" strokewidth="0" />
                        </g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="2.64" />
                        <g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" /> </g>
                    </svg>
                </div>
            </div>
            {showDescription && (
                <div className='bg-[#0495af] text-[18px] px-4 py-8 text-normal text-[#efefef] text-center'>
                    {product?.description}
                </div>
            )}
        </div>
    )
}