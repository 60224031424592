
export function isExactMatch(string, variable) {
    const regex = new RegExp(string)
    return variable?.match(regex)
}

export function getTextForLanguage(data, language) {
    if (typeof data === "string") {
      return data;
    } else {
        if(data[language]) {
            return data[language];
        }else{
            return data["en"];
        }
    }
  }