import React from 'react'

const SimpleButton = ({text, onClick, style}) => {
  return (
    <button style={style} className="box-border w-32 h-11 relative bottom-6 p-3 bg-background-secondary-1 rounded-3xl cursor-pointer flex items-center justify-center font-normal text-text-secondary-1" onClick={onClick}>
    {text}
  </button>
  )
}

export default SimpleButton