import './App.css';
import { CaptureScreen } from './components/captureScreen';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { Home } from './components/home';
import { UserInfo } from './components/userInfo';
import { ResultScreen } from './components/resultScreen';
import ClientResultsContainer from './components/ClientResult/ClientResultsContainer';
import NewWebcamComponent from './components/NewWebcamComponent';
import SuccessPage from './components/SuccessPage';
import { TranslationProvider } from './contexts/translationContext';
import { useEffect, useState } from 'react';
import { trackPageView } from './utils/googleAnalytics';


function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
}

function App() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    age: "",
    gender: "",
    race: "",
  });
  return (
  <TranslationProvider>
    <Router>
      <PageTracker />
      <div className="bg-background-primary-1 min-h-screen relative flex flex-col text-text-1 font-font-1 font-bold tracking-wide">
        <Routes>
          <Route exact path="/" element={<Home formData={formData} setFormData={setFormData} />} />
          <Route exact path="/capture" Component={CaptureScreen} />
          <Route exact path="/upload" element={<UserInfo formData={formData}/>} />
          <Route exact path="/success" Component={SuccessPage} />
          <Route exact path="/analysis/:patient_id/:hair_analysis_id" Component={ResultScreen} />
          <Route exact path="/results/:patient_id/:hair_analysis_id/:mainType/:subType" Component={ClientResultsContainer} />
          <Route exact path="/new_webcam" Component={NewWebcamComponent} />
        </Routes>
      </div>
    </Router>
  </TranslationProvider>
  );
}

export default App;
