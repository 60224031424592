import React, { useState } from "react";
import { getTextForLanguage } from "../utils";
import { useClinic } from "../hooks/useClinic";
import { useTranslation } from "../contexts/translationContext";
import SimpleButton from "./SimpleButton";

const RACE_OPTIONS = [
  "White",
  "Native Hawaiian or Other Pacific Islander",
  "Middle Eastern",
  "Indian or Alaska Native",
  "Hispanic or Latino",
  "Black or African American",
  "Asian",
];

const ContactDetails = ({ setStep, formData, setFormData }) => {
  const { clinicQuestionnaire } = useClinic();
  const { language, translate } = useTranslation();
  const [error, setError] = useState("");

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextClick = () => {
    // Client-side validation
    const errors = {};

    if (!formData.firstName) {
      errors.firstName = "The first name field is required.";
    }

    if (!formData.lastName) {
      errors.lastName = "The last name field is required.";
    }

    if (!formData.age) {
      errors.age = "The age field is required.";
    }

    if (!formData.gender) {
      errors.gender = "The gender field is required.";
    }

    if (!formData.race) {
      errors.race = "The race field is required.";
    }
    if (!formData.phone) {
      errors.phone = "The phone field is required.";
    }

    if (!formData.email) {
      errors.email = "The email field is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "The email must be a valid email address.";
    }

    // Check if there are any validation errors
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }
    setStep(2);
  };
  return (
    <div className="flex flex-col justify-center w-full items-center">
      <p className="text-text-3 font-font-3 text-base font-normal w-[92%] m-auto mt-1 mb-2 text-center">
        {getTextForLanguage(clinicQuestionnaire?.section1?.title, language)}
      </p>
      <input
        type="text"
        className="mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none"
        placeholder={translate("firstname")}
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
      />
      {error.firstName && (
        <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">{error.firstName}</p>
      )}
      <input
        type="text"
        className="mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none"
        placeholder={translate("lastname")}
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
      />
      {error.lastName && (
        <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">{error.lastName}</p>
      )}

      <input
        type="tel"
        className="mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none"
        placeholder={translate("phoneno")}
        name="phone"
        value={formData.phone}
        onChange={handleChange}
      />
      {error.phone && (
        <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">{error.phone}</p>
      )}
      <input
        type="text"
        className={`mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none`}
        placeholder={translate("email")}
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      {error.email && (
        <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">{error.email}</p>
      )}

      <input
        type="number"
        className="mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none"
        placeholder={translate("age")}
        name="age"
        value={formData.age}
        onChange={handleChange}
      />
      {error.age && (
        <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">{error.age}</p>
      )}
      <select
        className={`${!formData.race ? "text-gray-400" : ""} custom-select mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none`}
        placeholder={translate("race")}
        name="race"
        value={formData.race}
        onChange={handleChange}
      >
        <option
          className="text-gray-400"
          value=""
          disabled
          selected
        >
          Race
        </option>
        {RACE_OPTIONS.map(option => (
          <option
            key={option}
            value={option}
          >
            {option}
          </option>
        ))}
      </select>
      {error.race && (
        <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">{error.race}</p>
      )}

      <div className={`w-[85%] m-auto mt-0 mb-2 ${!error.gender ? "mb-2" : ""}`}>
        <p className="text-text-3 font-font-3 text-base font-normal m-auto mt-1 mb-1">
          {translate("please_select_gender")}
        </p>
        <div className="radiocontainer w-full flex gap-5 flex-wrap">
          {["M", "F", "O"].map(option => (
            <div key={option}>
              <input
                type="radio"
                id={option}
                name="gender"
                value={option}
                checked={formData.gender === option}
                onChange={handleChange}
                className="hidden"
              />
              <label
                htmlFor={option}
                className="flex items-center"
              >
                <div
                  className={`relative cursor-pointer inline-block h-0 p-[10px] border border-white rounded-full ${
                    formData.gender === option ? "bg-B7E5E4" : "bg-transparent"
                  }`}
                >
                  <div
                    className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full ${
                      formData.gender === option ? "opacity-100" : "opacity-0"
                    }`}
                  ></div>
                </div>
                <span className="text-font-3 text-base font-normal text-white relative left-2 cursor-pointer">
                  {translate(option === "M" ? "male" : option === "F" ? "female" : "others")}
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>

      {error.gender && (
        <p className="mt-4 mx-4 mb-2 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">{error.gender}</p>
      )}
      <SimpleButton
        text={"Next"}
        onClick={handleNextClick}
        style={{ marginTop: "1.7rem" }}
      />
    </div>
  );
};

export default ContactDetails;
