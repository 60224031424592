import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useNavigate, useParams } from "react-router-dom";

import { HeaderLogo } from "./HeaderLogo";
import Loading from "./loading";
import ShopifyProducts from "./ShopifyProducts";
import SimpleButton from "./SimpleButton";

import { useClinic } from "../hooks/useClinic"
import { PRODUCTS } from "../constants/products";

export const ResultScreen = () => {
  const { clinicProducts, getResultMetaData } = useClinic()
  const navigate = useNavigate()

  const { patient_id, hair_analysis_id } = useParams();
  
  const [patient, setPatient] = useState(null);
  const [products, setProducts] = useState([]);

  const [ready, setReady] = useState(true);
  const [hairAnalysis, setHairAnalysis] = useState(true);
  const [error, setError] = useState("");
  const [isMLImage, setIsMLImage] = useState(true);
  const [encounteredMLError, setEncounteredMLError] = useState(false);
  const [readMore, setReadMore] = useState(false);

  const clinicProductsToDisplay = useMemo(() => {
    if(!patient) return [];

    return patient?.hair_selfie_brand ? 
    [patient?.hair_selfie_brand] : [
      clinicProducts[Math.floor(Math.random() * clinicProducts?.length)]
    ]
  }, [clinicProducts, patient])

  const fetchAnalysis = useCallback(() => {
    return fetch(
      `${process.env.REACT_APP_API}/consumer/hair_analysis/${hair_analysis_id}`,
      {}
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setHairAnalysis(response.hair_analyses);
          setPatient(response.patient);
          if(
            !response?.hair_analyses?.[0]?.images?.ml_images?.image_path ||
            response?.hair_analyses?.[0]?.images?.ml_images?.image_path === "0"
          ) setEncounteredMLError(true)
        } else {
          setError(response.error);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setReady(true));
  }, [hair_analysis_id]);

  const fetchProducts = useCallback(() => {
    if(!clinicProducts?.includes(PRODUCTS.ZENAGEN)) return; 
    
      return fetch(
        `${process.env.REACT_APP_API}/patient/${399}/treatment-suggestion`, // TODO : use new API for getting products
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setTimeout(() => setProducts(response.data), 500);
          } else {
            setError(response.error);
          }
        })
        .catch((error) => {
          console.error(error);
          setError("Some Error Occured. Please Try Again Later");
        })
        .finally(() => { });
  }, [clinicProducts])



  useEffect(() => {
    setReady(false);

    Promise.all([
      fetchAnalysis(),
      fetchProducts()
    ])
    .then(() => {})
    .catch((error) => {
      console.error(error);
      setError("Some Error Occurred. Please Try Again Later");
    }).finally(() => setReady(true));
  }, [fetchAnalysis, fetchProducts]);

    const metaData = getResultMetaData(hairAnalysis?.[0]?.hair_thinning?.class, hairAnalysis?.[0]?.hair_thinning?.label_desc)
  return (
    <>
      <HeaderLogo />
      <div className={`${ready ? '' : "hidden"}`}>
        <div
          className="w-full"
          style={{ borderRadius: "0px 0px 20px 20px" }}
        >
          <div
            className="mx-auto w-full"
            style={{
              maxWidth: "600px",
              minheight: "calc(100vh - 96px)",
              zIndex: "1000",
            }}
          >
            <div className="text-center">
              <p className="text-text-3 font-font-3 font-semibold text-xl">A.I. Hair Growth Tracking</p>
            </div>
            {error.image && (
              <p className="m-auto mt-1 text-sm text-center text-red-600">
                {error.image}
              </p>
            )}
            <div className="m-auto  mt-4  flex justify-center items-center flex-col">
            <div className={`mx-auto w-[360px] overflow-hidden rounded-2xl ${!encounteredMLError ? 'cursor-pointer' : ''}`} onClick={() => setIsMLImage(prev => !encounteredMLError ? !prev : false)}>
            {!isMLImage || encounteredMLError ? <img
              className="w-[100%] mx-auto"
              src={`${hairAnalysis?.[0]?.images?.portrait?.image_path}`}
              style={{ display:"block" }}
              alt="captued resource"
            />
            : <img
              className="w-[100%] mx-auto"
              src={`${hairAnalysis?.[0]?.images?.ml_images?.image_path}`}
              style={{ display:"block" }}
              alt="captued resource"
            />}
              </div>
              {encounteredMLError ? (
                <div className="flex flex-col items-center justify-center mt-4 gap-5">
                  <p className={`text-text-3 font-font-3 font-semibold text-xl text-center`}>
                    Unfortunately, there was an error in the analysis process, please try again now to complete your analysis.
                  </p>
                  <SimpleButton 
                    text={'Try Again'} 
                    onClick={() => navigate("/")} 
                    style={{position: 'static'}}
                    />
                </div>
              ) : (
                <p className={`text-text-3 font-font-3 font-semibold text-xl mt-3 text-center`}>
                  {
                    hairAnalysis?.[0]?.hair_thinning?.label === "Normal" ? 
                    hairAnalysis?.[0]?.hair_thinning?.label + ' Status' : 
                    hairAnalysis?.[0]?.hair_thinning?.label
                  }
                </p>
              )}
              {!encounteredMLError && <>
              <div className="flex items-center flex-col gap-1 justify-center whitespace-nowrap">
                <p className="text-text-primary-1 font-font-3 font-normal text-center text-base">
                  {hairAnalysis?.[0]?.hair_thinning?.class && 'Class ' + hairAnalysis?.[0]?.hair_thinning?.class}
                </p>
                <p className="text-text-primary-1 font-font-3 font-thin text-center text-base">
                  {
                    (Boolean(hairAnalysis?.[0]?.hair_thinning?.label_desc) && hairAnalysis?.[0]?.hair_thinning?.label_desc ) + ' Loss'
                  }
                </p>
                <p className="text-text-primary-1 font-font-3 font-thin text-center text-base">
                  {
                    (Boolean(hairAnalysis?.[0]?.hair_thinning?.label === "Normal") && 'Very Healthy Hair')
                  }
                </p>
              </div>
              
              {metaData && <div className="relative w-full mb-6 text-white min-h-3 mt-3">
                <div className='flex items-center gap-[10px] font-font-3 text-[18px] font-light text-white absolute right-5 mt-2 cursor-pointer' onClick={() => setReadMore(prev => !prev)}>
                    Read More
                    <svg
                        
                        class={`cursor-pointer ${readMore ? 'rotate-0' : 'rotate-180'}`}
                        fill="#ffffff"
                        height="25px"
                        width="25px"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/10000/svg"
                        viewBox="-300.3 -300.3 930.60 930.60"
                        stroke="#ffffff"
                        stroke-width="33"
                    >
                        <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                            <rect x="-300.3" y="-300.3" width="930.60" height="930.60" rx="465.3" fill="#0495af" strokewidth="0" />
                        </g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="2.64" />
                        <g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" /> </g>
                    </svg>
                </div>
                {readMore && <div className="bg-[#4093AC] mt-12 mx-5 p-5 text-center">
                <div className="flex flex-col  gap-4" dangerouslySetInnerHTML={{ __html: metaData }} />
                </div>}
              </div>}
              {Boolean(clinicProducts?.length && patient?.gender) && (
                <>
                  <div className="text-text-3 font-font-3 text-center font-semibold text-xl mt-5 capitalize">
                    Based on your thinning hair assessment, <br />here are the recommended solutions.
                  </div>
                  <ShopifyProducts 
                    clinicProducts={clinicProductsToDisplay}
                    clientGender={patient?.gender === "F" ? "female" : "male"}
                    products={products}
                  />
                </>
              )}
              </>}
            </div>
          </div>
        </div>
      </div>
      <Loading message={"Loading Details"} ready={ready} />
    </>
  );
};
