// ProgressBar.js
import React from 'react';

const ProgressBar = ({ percentage }) => {
  return (
    <div className="relative pt-1 w-[85%] m-auto h-6"> 
      <div className="flex">
        <span className="text-text-3 text-sm font-thin absolute right-0 -top-5">{percentage}%</span>
        <div className="flex-grow bg-[#B7E5E4] h-2 rounded-md absolute w-full opacity-[0.2]"></div>
        <div
          style={{ width: `${percentage}%`}}
          className="bg-primary h-2 rounded-md bg-background-secondary-1 z-10 transition-all duration-500 ease-in-out "
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
