import React, { useEffect, useRef } from "react";

const NewWebcamComponent = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const getVideo = async () => {
            try {
                const videoConstraints = {
                    width: { ideal: 720 }, // Width smaller than height
                    height: { ideal: 1280 }, // Height greater than width
                };

                const stream = await navigator.mediaDevices.getUserMedia({
                    video: videoConstraints,
                });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (error) {
                console.error("Error accessing the webcam", error);
            }
        };

        getVideo();
    }, []);

    return <div>

		<video ref={videoRef} autoPlay />
	</div>;
};

export default NewWebcamComponent;
