import React from 'react'
import { useTranslation } from '../contexts/translationContext';
import { getTextForLanguage } from '../utils';

const FormField = ({userAnswers, setUserAnswers, type, section}) => {
  const { language } = useTranslation() ;
    const sectionName = `section${section}`
    
    if(section == 1)return;

    if(type === "radio"){
        return <>
        <p className="text-text-3 font-font-3 text-base font-normal w-[90%]  m-auto mt-1 mb-3">
          {getTextForLanguage(userAnswers[sectionName].question, language)}
        </p>
        <div className="options w-full flex justify-between flex-wrap">
            {Object.keys(userAnswers[sectionName].options).map((optionKey) => (
              <div key={optionKey} className="w-full mb-2">
                <input
                  type="radio"
                  id={optionKey}
                  name={`${sectionName}Option`}
                  value={optionKey}
                  checked={userAnswers[sectionName].options[optionKey].value}
                  onChange={() => {
                    const updatedOptions = { ...userAnswers[sectionName].options };
                    Object.keys(updatedOptions).forEach((key) => {
                      updatedOptions[key].value = key === optionKey;
                    });
                    setUserAnswers({
                      ...userAnswers,
                      [sectionName]: {
                        ...userAnswers[sectionName],
                        options: updatedOptions,
                        answer: getTextForLanguage(userAnswers[sectionName].options[optionKey].text, language)
                      },
                    });
                  }}
                  className="hidden"
                />
                <label htmlFor={optionKey} className="flex items-center">
                  <div
                    className={`relative cursor-pointer inline-block h-0 p-[10px] border border-white rounded-full ${
                      userAnswers[sectionName].options[optionKey].value
                        ? "bg-B7E5E4"
                        : "bg-transparent"
                    }`}
                  >
                    <div
                      className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full ${
                        userAnswers[sectionName].options[optionKey].value
                          ? "opacity-100"
                          : "opacity-0"
                      }`}
                    ></div>
                  </div>
                  <span className="text-text-3 font-font-3 text-base font-normal relative left-2">
                    {getTextForLanguage(userAnswers[sectionName].options[optionKey].text, language)}
                  </span>
                </label>
              </div>
            ))}

        </div>
      </>
    }
    
    if (type === "multiselect") {
      return (
        <>
          <p className="text-text-3 font-font-3 text-base font-normal w-[90%] m-auto mt-1 mb-3">
            {getTextForLanguage(userAnswers[sectionName].question, language)}
          </p>
          <div className="options w-full flex justify-between flex-wrap">
            {Object.keys(userAnswers[sectionName].options).map((optionKey) => (
              <div key={optionKey} className="w-full mb-2">
                <input
                  type="checkbox"
                  id={optionKey}
                  name={`${sectionName}Option`}
                  value={optionKey}
                  checked={userAnswers[sectionName].options[optionKey].value}
                  onChange={() => {
                    const updatedOptions = { ...userAnswers[sectionName].options };
                    updatedOptions[optionKey].value = !updatedOptions[optionKey].value;
                    setUserAnswers({
                      ...userAnswers,
                      [sectionName]: {
                        ...userAnswers[sectionName],
                        options: updatedOptions,
                        answer: Object.keys(updatedOptions)
                          .filter((key) => updatedOptions[key].value)
                          .map((key) => getTextForLanguage(userAnswers[sectionName].options[key].text, language))
                          .join(", "),
                      },
                    });
                  }}
                  className="hidden"
                />
                <label htmlFor={optionKey} className="flex items-center">
                  <div
                    className={`relative cursor-pointer inline-block h-0 p-[10px] border border-white rounded-full ${
                      userAnswers[sectionName].options[optionKey].value
                        ? "bg-B7E5E4"
                        : "bg-transparent"
                    }`}
                  >
                    <div
                      className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full ${
                        userAnswers[sectionName].options[optionKey].value
                          ? "opacity-100"
                          : "opacity-0"
                      }`}
                    ></div>
                  </div>
                  <span className="text-text-3 font-font-3 text-base font-normal relative left-2">
                    {getTextForLanguage(userAnswers[sectionName].options[optionKey].text, language)}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </>
      );
    }

    if (type === "radioandtext") {
      return (
        <>
          <p className="text-text-3 font-font-3 text-base font-normal w-[90%] m-auto mt-1 mb-3">
             {getTextForLanguage(userAnswers[sectionName].question, language)}
          </p>
          <div className="options w-[90%] flex justify-between flex-wrap">
            {Object.keys(userAnswers[sectionName].options).map((optionKey) => (
              <div key={optionKey} className="w-full mb-2">
                <input
                  type="radio"
                  id={optionKey}
                  name={`${sectionName}Option`}
                  value={optionKey}
                  checked={userAnswers[sectionName].options[optionKey].value}
                  onChange={() => {
                    const updatedOptions = { ...userAnswers[sectionName].options };
                    Object.keys(updatedOptions).forEach((key) => {
                      updatedOptions[key].value = key === optionKey;
                    });
                    setUserAnswers({
                      ...userAnswers,
                      [sectionName]: {
                        ...userAnswers[sectionName],
                        options: updatedOptions,
                        answer: getTextForLanguage(userAnswers[sectionName].options[optionKey].text, language)
                      },
                    });
                  }}
                  className="hidden"
                />
                <label htmlFor={optionKey} className="flex items-start">
                  <div
                    className={`relative cursor-pointer inline-block w-5 h-5 border border-white rounded-full ${
                      userAnswers[sectionName].options[optionKey].value
                        ? "bg-B7E5E4"
                        : "bg-transparent"
                    }`}
                  >
                    <div
                      className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full ${
                        userAnswers[sectionName].options[optionKey].value
                          ? "opacity-100"
                          : "opacity-0"
                      }`}
                    ></div>
                  </div>
                  <span className="text-text-3 font-font-3 text-base font-normal relative left-2 cursor-pointer">
                    {getTextForLanguage(userAnswers[sectionName].options[optionKey].text, language)}
                  </span>
                </label>
              </div>
            ))}
            <textarea
              type="text"
              className={`mt-2 px-5 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-full ${
                userAnswers[sectionName].options.yes.value ? "h-[8rem] py-2" : "h-0"
              } overflow-hidden focus:outline-none transition-all duration-500 ease-in-out`}
              placeholder="Treatment List"
              name="treatmentList"
              spellCheck="true"
              value={userAnswers[sectionName].textValue}
              onChange={(e) => {
                setUserAnswers({
                  ...userAnswers,
                  [sectionName]: {
                    ...userAnswers[sectionName],
                    textValue: e.target.value,
                    answer: e.target.value
                  },
                });
              }}
            />
          </div>
        </>
      );
    }
    


    if(type ==="text"){
        return <>
         <p className="text-text-3 font-font-3 text-base font-normal w-[90%] m-auto mt-1 mb-3">
             {getTextForLanguage(userAnswers[sectionName].question, language)}
         </p>
         <div className="options w-[90%] flex justify-between flex-wrap">
         <textarea
             type="text"
             className={`mt-2 px-5 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-full h-[8rem] py-2 overflow-hidden focus:outline-none transition-all duration-500 ease-in-out`}
             placeholder="Treatment List"
             name="treatmentList"
             value={userAnswers[sectionName].textValue}
             onChange={(e) => {
             setUserAnswers({
                 ...userAnswers,
                 [sectionName]: {
                 ...userAnswers[sectionName],
                 textValue: e.target.value,
                 answer: e.target.value
                 },
             });
             }}
         />
         </div>
     </>
 }
  return (
    <>
  </>
  )
}

export default FormField