import { PRODUCTS }  from "../constants/products"

// logos
import VitallyLogo from "../assets/logos/vitally-hair.png"

const subdomainMap = Object.freeze({
  "vitallyhair": {
    "id": 138,
    "name": "Vitallyhair",
    "logo": VitallyLogo,
    "questionnairePageHeading": "Personalization",
    "questionnaire": {
      "section1": {
        "title": "Complete this to receive your personal hair analysis & recommendations"
      },
      "section2": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Have you recently experienced any of these issues?",
        "type": "multiselect",
        "options": {
          "menopause": {
            "text": "Menopause",
            "value": false
          },
          "stressAnxiety": {
            "text": "Stress / Anxiety",
            "value": false
          },
          "pregnancy": {
            "text": "Pregnancy",
            "value": false
          },
          "covid": {
            "text": "Covid",
            "value": false
          },
          "alcohol": {
            "text": "Alcohol",
            "value": false
          },
          "medicineMedical": {
            "text": "Medicine / Medical",
            "value": false
          },
          "none": {
            "text": "None",
            "value": false
          }
        }
      },
      "section3": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "How long have you had hair concerns?",
        "type": "radio",
        "options": {
          "justNow": {
            "text": "Just now",
            "value": false
          },
          "threeMonthsAgo": {
            "text": "3 months ago",
            "value": false
          },
          "sixMonthsAgo": {
            "text": "6 months ago",
            "value": false
          },
          "oneYearAgo": {
            "text": "1 year ago",
            "value": false
          },
          "threeYearsAgo": {
            "text": "3 years ago",
            "value": false
          },
          "overFiveYears": {
            "text": "Over 5 years",
            "value": false
          }
        }
      },
      "section4": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "What is your concern level?",
        "type": "radio",
        "options": {
          "slight": {
            "text": "Slightly concerned",
            "value": false
          },
          "veryconcerned": {
            "text": "Very concerned",
            "value": false
          },
          "dosomething": {
            "text": "Help me now!",
            "value": false
          }
        }
      },
      "section5": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Have you started and completed any hair growth treatments in the past? If yes, please list.",
        "type": "radioandtext",
        "options": {
          "yes": {
            "text": "Yes",
            "value": false
          },
          "no": {
            "text": "No",
            "value": false
          }
        },
        "textValue": ""
      },
      "section6": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Are you receiving any hair loss treatments now? If yes, please list treatments.",
        "type": "radioandtext",
        "options": {
          "yes": {
            "text": "Yes",
            "value": false
          },
          "no": {
            "text": "No",
            "value": false
          }
        },
        "textValue": ""
      },
      "section7": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Are you more likely to buy a product if you can track the results to see if it is working for you?",
        "type": "radio",
        "options": {
          "yes": {
            "text": "Yes",
            "value": false
          },
          "no": {
            "text": "No",
            "value": false
          }
        },
        "textValue": ""
      }
    },
    "successPage": {
      "heading": "Fantastic!",
      "description": "That’s it! You will soon get an SMS and email with your results!<br/><br/>The journey to youthful, rejuvenated hair begins now! <br/><br/>Get ready!",
      "link": "http://vitallyhair.com/"
    },
    "products": [
      PRODUCTS.ZENAGEN,
      PRODUCTS.ACTIIV
    ],
    getResultMetaData: (classValue, hairThinningPercentage) => {
      // if an error is encountered, return nothing
      if(!classValue && hairThinningPercentage !== '0-10%') return null;

      // metadata for 0-10% category
      if(hairThinningPercentage === '0-10%') return `
        <h3 className="w-2/3 m-auto">Your initial results are very positive. There could be isolated areas of concern for you, but overall, no major issues were seen on first analysis.</h3>
        <p class=" font-font-3 font-thin">We recommend the following considerations: </p>
        <p class=" font-font-3 font-thin">1. When possible, do an analysis each 4-8 weeks, preferably with wet and parted hair, to continue monitoring and measuring your hair health and growth.</p>
        <p class=" font-font-3 font-thin">2. Although the results were positive, consider anti-aging practices for your hair, similar to how you do your face and skin care. </p>
        <p class=" font-font-3 font-thin">3. For isolated areas of concern, we recommend a serum to improve thickness and growth. </p>
        <p class=" font-font-3 font-thin">4. If you want further analysis or consult, use the in-app chat on the home screen (link to <a target="_blank" href="http://vitallyhair.com" class="underline">http://vitallyhair.com</a>). </p>
        <h3 class="text-center m-auto">Thank you for using VitallyHair, and please share with friends. </h3>
      `

      // default metadata
      return `<h3 className="w-2/3 m-auto">Your results show you are in Level ${classValue} of Hair Loss</h3>
      <p class=" font-font-3 font-thin">That means you are somewhere around ${hairThinningPercentage} down from your peak hair. This is not uncommon, but it is important you take steps now to maintain or improve your hair for the long term.</p>
      <p class=" font-font-3 font-thin">It is possible if you follow &#x26; adhere to the recommendations provided here.</p>
      <p class=" font-font-3 font-thin">Using VitallyHair, you can track the success of these solutions, and if you adhere to them and they are not successful, you can get your money back - guaranteed.</p>
      <h3 class="text-center m-auto">Say YES to youthful, vibrant, full hair.</h3>`;
    },
    "displayProductResults": true,
    "displayIntroPage": true,
    "showContactPageFirst": true
  },
  "hairmax": {
    "id": 139,
    "name": "Hairmax",
    "questionnairePageHeading": "Personalization",
    "questionnaire": {
      "section1": {
        "title": "Complete this to receive your personal hair analysis & recommendations"
      },
      "section2": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "When did you first notice hair loss?",
        "type": "radio",
        "options": {
          "justNow": {
            "text": "Just now",
            "value": false
          },
          "threeMonthsAgo": {
            "text": "3 months ago",
            "value": false
          },
          "sixMonthsAgo": {
            "text": "6 months ago",
            "value": false
          },
          "oneYearAgo": {
            "text": "1 year ago",
            "value": false
          },
          "threeYearsAgo": {
            "text": "3 years ago",
            "value": false
          },
          "overFiveYears": {
            "text": "Over 5 years",
            "value": false
          }
        }
      },
      "section3": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Do any of these family members suffer from hair loss?",
        "type": "radio",
        "options": {
          "father": {
            "text": "Father",
            "value": false
          },
          "mother": {
            "text": "Mother",
            "value": false
          },
          "sibling": {
            "text": "Siblings",
            "value": false
          }
        }
      },
      "section4": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "What is your concern level?",
        "type": "radio",
        "options": {
          "slight": {
            "text": "Slightly concerned",
            "value": false
          },
          "veryconcerned": {
            "text": "Very concerned",
            "value": false
          },
          "dosomething": {
            "text": "Help me now!",
            "value": false
          }
        }
      },
      "section5": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Have you started and completed any hair growth treatments in the past? If yes, please list.",
        "type": "radioandtext",
        "options": {
          "yes": {
            "text": "Yes",
            "value": false
          },
          "no": {
            "text": "No",
            "value": false
          }
        },
        "textValue": ""
      },
      "section6": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Are you receiving any hair loss treatments now? If yes, please list treatments.",
        "type": "radioandtext",
        "options": {
          "yes": {
            "text": "Yes",
            "value": false
          },
          "no": {
            "text": "No",
            "value": false
          }
        },
        "textValue": ""
      },
      "section7": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Are you more likely to buy a product if you can track the results to see if it is working for you?",
        "type": "radio",
        "options": {
          "yes": {
            "text": "Yes",
            "value": false
          },
          "no": {
            "text": "No",
            "value": false
          }
        },
        "textValue": ""
      }
    },
    "successPage": {
      "heading": "Fantastic!",
      "description": "Your image & information has been uploaded to a HIPAA-compliant server.<br/><br/>Your results will be sent to you in a couple minutes.<br/><br/>Get ready!"
    },
    "products": [
      PRODUCTS.HAIRMAX
    ],
    "displayProductResults": false,
  },
  "kerafactor": {
    "name": "Kerafactor",
    "questionnairePageHeading": "Personalization",
    "questionnaire": {
      "section1": {
        "title": "Complete this to receive your personal hair analysis & recommendations"
      },
      "section2": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "When did you first notice hair loss?",
        "type": "radio",
        "options": {
          "justNow": {
            "text": "Just now",
            "value": false
          },
          "threeMonthsAgo": {
            "text": "3 months ago",
            "value": false
          },
          "sixMonthsAgo": {
            "text": "6 months ago",
            "value": false
          },
          "oneYearAgo": {
            "text": "1 year ago",
            "value": false
          },
          "threeYearsAgo": {
            "text": "3 years ago",
            "value": false
          },
          "overFiveYears": {
            "text": "Over 5 years",
            "value": false
          }
        }
      },
      "section3": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Do any of these family members suffer from hair loss?",
        "type": "radio",
        "options": {
          "father": {
            "text": "Father",
            "value": false
          },
          "mother": {
            "text": "Mother",
            "value": false
          },
          "sibling": {
            "text": "Siblings",
            "value": false
          }
        }
      },
      "section4": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "What is your concern level?",
        "type": "radio",
        "options": {
          "slight": {
            "text": "Slightly concerned",
            "value": false
          },
          "veryconcerned": {
            "text": "Very concerned",
            "value": false
          },
          "dosomething": {
            "text": "Help me now!",
            "value": false
          }
        }
      },
      "section5": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Have you started and completed any hair growth treatments in the past? If yes, please list.",
        "type": "radioandtext",
        "options": {
          "yes": {
            "text": "Yes",
            "value": false
          },
          "no": {
            "text": "No",
            "value": false
          }
        },
        "textValue": ""
      },
      "section6": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Are you receiving any hair loss treatments now? If yes, please list treatments.",
        "type": "radioandtext",
        "options": {
          "yes": {
            "text": "Yes",
            "value": false
          },
          "no": {
            "text": "No",
            "value": false
          }
        },
        "textValue": ""
      },
      "section7": {
        "title": "A few quick details to supplement your AI\u2011powered analysis",
        "question": "Are you more likely to buy a product if you can track the results to see if it is working for you?",
        "type": "radio",
        "options": {
          "yes": {
            "text": "Yes",
            "value": false
          },
          "no": {
            "text": "No",
            "value": false
          }
        },
        "textValue": ""
      }
    },
    "successPage": {
      "heading": "Fantastic!",
      "description": "Your image & information has been uploaded to a HIPAA-compliant server.<br/><br/>Your results will be sent to you in a couple minutes.<br/><br/>Get ready!",
    },
    "products": [
      PRODUCTS.KERAFACTOR
    ],
    "displayProductResults": false,
  },
})

export default subdomainMap;