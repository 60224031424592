import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { HeaderLogo } from "./HeaderLogo";
import Loading from "./loading";
import TopBarNavigation from "./TopBarNavigation";
import ProgressBar from "./ProgressBar";
import FormField from "./FormField";
import { DEFAULT_CLINIC_NAME, useClinic } from "../hooks/useClinic";
import { useTranslation } from "../contexts/translationContext";
import { getTextForLanguage } from "../utils";
import { useRouter } from "../hooks/useRouter";
import { trackEvent } from "../utils/googleAnalytics";

const RACE_OPTIONS = [
  "White",
  "Native Hawaiian or Other Pacific Islander",
  "Middle Eastern",
  "Indian or Alaska Native",
  "Hispanic or Latino",
  "Black or African American",
  "Asian",
]

export const UserInfo = ({formData}) => {
  const {
    clinicId,
    clinicQuestionnaire,
    questionnairePageHeading,
    clinicSuccessPage,
    clinicName,
    showContactPageFirst,
    getClinicProductForPatient,
  } = useClinic();
  const { routeWithParams, referrer } = useRouter();

  const { language, translate } = useTranslation();
  const [firstName, setFirstName] = useState(formData?.firstName || "");
  const [lastName, setLastName] = useState(formData?.lastName || "");
  const [email, setEmail] = useState(formData?.email || "");
  const [phone, setPhone] = useState(formData?.phone || "");
  const [age, setAge] = useState(formData?.age || "");
  const [gender, setGender] = useState(formData?.gender || "");
  const [race, setRace] = useState(formData?.race || "");
  const [error, setError] = useState("");
  const [ready, setReady] = useState(true);
  const [section, setSection] = useState(showContactPageFirst ? 2 : 1); // section 1 is for patient details we have already displayed the questions in the form for section 1.
  const [userAnswers, setUserAnswers] = useState(
    Object.entries(clinicQuestionnaire)?.reduce((acc, question) => {
      const [key, value] = question;

      if (value?.question) {
        acc[key] = { ...value, answer: "" };
        return acc;
      }

      acc[key] = value;
      return acc;
    }, {})
  );
  console.log("userAnswers", userAnswers);
  const location = useLocation();
  const navigate = useNavigate();
  const getFileFromBase64 = (string64, fileName) => {
    if (!string64) return null;

    const trimmedString = string64.replace("data:image/jpeg;base64,", "");
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = "image/png";
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  };

  const image = getFileFromBase64(location.state?.img, "selfie.jpg");
  const sections = Object.keys(userAnswers); // Get the list of sections dynamically

  const isLastSection = section === sections.length;

  const upload = () => {
    setReady(false);
    trackEvent('button_click', { button_name: 'Form Submitted', action: 'Clicked' });
    const formData = new FormData();

    if (clinicId) formData.append("clinic_id", clinicId);
    if (referrer) formData.append("referrer", referrer);
    if (Boolean(getClinicProductForPatient()))
      formData.append("hair_selfie_brand", getClinicProductForPatient());

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("image", image);
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("race", race);

    let count = 0;
    Object.values(userAnswers)?.forEach((question) => {
      if (!(question?.question && question?.answer)) return;
      formData.append(
        `questionnaire[${count}][question]`,
        getTextForLanguage(question?.question, language)
      );
      formData.append(`questionnaire[${count}][answer]`, question?.answer);
      count++;
    });

    fetch(`${process.env.REACT_APP_API}/consumer/hair_analysis/create`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          navigate("/success");
        } else {
          setError(response.error);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setTimeout(() => setReady(true), 500));
  };

  const handleNextSection = () => {
    if (section === 1) {
      trackEvent('button_click', { button_name: 'User Details Entered', action: 'Clicked' });

      // Client-side validation
      const errors = {};

      if (!firstName) {
        errors.firstName = "The first name field is required.";
      }

      if (!lastName) {
        errors.lastName = "The last name field is required.";
      }

      if (!age) {
        errors.age = "The age field is required.";
      }

      if (!gender) {
        errors.gender = "The gender field is required.";
      }

      if (!race) {
        errors.race = "The race field is required.";
      }
      if (!phone) {
        errors.phone = "The phone field is required.";
      }

      if (!email) {
        errors.email = "The email field is required.";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        errors.email = "The email must be a valid email address.";
      }

      // Check if there are any validation errors
      if (Object.keys(errors).length > 0) {
        setError(errors);
        // setReady(true);
        return;
      }
    }
    if (section < sections.length) {
      setSection((prev) => prev + 1);
    }
  };
  const handlePreviousSection = () => {
    if (section > 1) {
      setSection(section - 1);
    } else {
      navigate(routeWithParams("/"));
    }
  };

  const calculatePercentage = () => {
    const totalSections = Object.keys(userAnswers).length;
    return Math.floor(((section - 1) / totalSections) * 100);
  };

  return (
    <>
      <div className={ready ? `` : `hidden`}>
        <HeaderLogo />
        <TopBarNavigation
          text={questionnairePageHeading}
          onPrevClick={() => handlePreviousSection()}
          onCloseClick={() => navigate("/")}
        />

        {section !== 1 && (
          <p className="text-text-3 font-light text-center px-8 font-font-3 mt-6 -mb-3">
            {getTextForLanguage(
              clinicQuestionnaire?.[`section${section}`]?.title,
              language
            )}
          </p>
        )}

        <div className="container mx-auto mt-10 w-full">
          <ProgressBar percentage={calculatePercentage()} />
        </div>
        <div className={`flex flex-col ${section === 1 && "mt-4"}`}>
          {section === 1 && (
            <div className="m-auto w-40 h-40 rounded-xl flex items-center overflow-hidden bg-red-100">
              <img src={location.state?.img} alt="selfie" />
            </div>
          )}
          {error.image && (
            <p className="m-auto mt-1 text-sm text-center text-red-600">
              {error.image}
            </p>
          )}
          <div className="w-[85%] mt-8 mb-24 m-auto relative form-holder top-0">
            <form
              id="upload"
              className=" flex flex-col items-center "
              onSubmit={(event) => {
                event.preventDefault();
                upload();
              }}
            >
              {section === 1 && (
                <>
                  <p className="text-text-3 font-font-3 text-base font-normal w-[92%] m-auto mt-1 mb-2">
                    {getTextForLanguage(
                      clinicQuestionnaire?.section1?.title,
                      language
                    )}
                  </p>
                  <input
                    type="text"
                    className="mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none"
                    placeholder={translate("firstname")}
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {error.firstName && (
                    <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">
                      {error.firstName}
                    </p>
                  )}
                  <input
                    type="text"
                    className="mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none"
                    placeholder={translate("lastname")}
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {error.lastName && (
                    <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">
                      {error.lastName}
                    </p>
                  )}

                  <input
                    type="tel"
                    className="mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none"
                    placeholder={translate("phoneno")}
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {error.phone && (
                    <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">
                      {error.phone}
                    </p>
                  )}
                  <input
                    type="text"
                    className={`mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none`}
                    placeholder={translate("email")}
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {error.email && (
                    <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">
                      {error.email}
                    </p>
                  )}

                  <input
                    type="number"
                    className="mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none"
                    placeholder={translate("age")}
                    name="age"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                  />
                  {error.age && (
                    <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">
                      {error.age}
                    </p>
                  )}

                  <select
                    className={`
                      ${!race ? 'text-gray-400' : ''} custom-select mt-2 px-6 py-2 bg-text-primary-1 text-[#3E6975] rounded-lg text-base font-light font-font-3 w-[85%] focus:outline-none
                      `}
                    placeholder={translate("race")}
                    name="race"
                    value={race}
                    onChange={(e) => setRace(e.target.value)}
                  >
                    <option className="text-gray-400" value="" disabled selected>
                      Race
                    </option>
                    {RACE_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {error.race && (
                    <p className="mt-1 mx-4 mb-1 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">
                      {error.race}
                    </p>
                  )}

                  <div
                    className={`w-[85%] m-auto mt-0 mb-2 ${
                      !error.gender ? "mb-2" : ""
                    }`}
                  >
                    <p className="text-text-3 font-font-3 text-base font-normal m-auto mt-1 mb-1">
                      {translate("please_select_gender")}
                    </p>
                    <div className="radiocontainer w-full flex gap-5 flex-wrap">
                      <div>
                        <input
                          type="radio"
                          id={"M"}
                          name={`gender`}
                          value={"M"}
                          checked={gender === "M"}
                          onChange={() => setGender("M")}
                          className="hidden"
                        />
                        <label htmlFor={"M"} className="flex items-center">
                          <div
                            className={`relative cursor-pointer inline-block h-0 p-[10px] border border-white rounded-full ${
                              gender === "M" ? "bg-B7E5E4" : "bg-transparent"
                            }`}
                          >
                            <div
                              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full ${
                                gender === "M" ? "opacity-100" : "opacity-0"
                              }`}
                            ></div>
                          </div>
                          <span className="text-text-3 font-font-3 text-base font-normal relative left-2 cursor-pointer">
                            {translate("male")}
                          </span>
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id={"F"}
                          name={`gender`}
                          value={"F"}
                          checked={gender === "F"}
                          onChange={() => setGender("F")}
                          className="hidden"
                        />
                        <label htmlFor={"F"} className="flex items-center">
                          <div
                            className={`relative cursor-pointer inline-block h-0 p-[10px] border border-white rounded-full ${
                              gender === "F" ? "bg-B7E5E4" : "bg-transparent"
                            }`}
                          >
                            <div
                              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full ${
                                gender === "F" ? "opacity-100" : "opacity-0"
                              }`}
                            ></div>
                          </div>
                          <span className="text-text-3 font-font-3 text-base font-normal relative left-2 cursor-pointer">
                            {translate("female")}
                          </span>
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id={"O"}
                          name={`gender`}
                          value={"O"}
                          checked={gender === "O"}
                          onChange={() => setGender("O")}
                          className="hidden"
                        />
                        <label htmlFor={"O"} className="flex items-center">
                          <div
                            className={`relative cursor-pointer inline-block h-0 p-[10px] border border-white rounded-full ${
                              gender === "O" ? "bg-B7E5E4" : "bg-transparent"
                            }`}
                          >
                            <div
                              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full ${
                                gender === "O" ? "opacity-100" : "opacity-0"
                              }`}
                            ></div>
                          </div>
                          <span className="text-text-3 font-font-3 text-base font-normal relative left-2 cursor-pointer">
                            {translate("others")}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {error.gender && (
                    <p className="mt-4 mx-4 mb-2 text-sm text-[#FF6384] w-[85%] font-font-3 font-normal">
                      {error.gender}
                    </p>
                  )}
                </>
              )}

              {section === 2 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section2.type}
                  section={2}
                />
              )}

              {section === 3 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section3.type}
                  section={3}
                />
              )}

              {section === 4 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section4.type}
                  section={4}
                />
              )}

              {section === 5 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section5.type}
                  section={5}
                />
              )}

              {section === 6 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section6.type}
                  section={6}
                />
              )}

              {section === 7 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section7.type}
                  section={7}
                />
              )}

              {section === 8 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section8.type}
                  section={8}
                />
              )}

              {section === 9 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section9.type}
                  section={9}
                />
              )}

              {section === 10 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section10.type}
                  section={10}
                />
              )}

              {section === 11 && (
                <FormField
                  userAnswers={userAnswers}
                  setUserAnswers={setUserAnswers}
                  type={userAnswers.section11.type}
                  section={11}
                />
              )}

              <input
                type="button"
                className="box-border w-32 h-11 absolute -bottom-20 p-3 bg-background-secondary-1 rounded-3xl cursor-pointer flex items-center justify-center font-normal text-text-secondary-1"
                value={isLastSection ? translate("submit") : translate("next")}
                onClick={() => (isLastSection ? upload() : handleNextSection())}
              />
            </form>
          </div>
        </div>
      </div>
      <Loading message={"Loading Details"} ready={ready} />
    </>
  );
};
