import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'G-JEDEHHBZMC', // Replace with your Google Analytics ID
};

TagManager.initialize(tagManagerArgs);

export const trackPageView = (path) => {
  window.dataLayer.push({
    event: 'page_view',
    page_path: path,
  });
};

export const trackEvent = (eventName, eventParams) => {
  window.dataLayer.push({
    event: eventName,
    ...eventParams,
  });
};
