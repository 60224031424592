import { useCallback, useMemo } from "react"

// data
import ClinicSubdomainMap from "../data/subdomain-map"

// constants
import { questionnaire as defaultQuestionnaire } from "../constants/questionnaire"

// logos
import HeaderLogoSvg from '../assets/images/HeaderLogo.svg';
import { useTranslation } from "../contexts/translationContext";
import { getTextForLanguage } from "../utils";

export const DEFAULT_CLINIC_NAME = "GroTrack"

export const useClinic = () => {
  const subdomain = useMemo(() => window.location.hostname?.split(".")?.[0], []);
  const { language } = useTranslation();

  // destructure clinic
  const clinicId = ClinicSubdomainMap[subdomain]?.id;
  const clinicQuestionnaire = ClinicSubdomainMap[subdomain]?.questionnaire || defaultQuestionnaire.questionnaire;
  const questionnairePageHeading = ClinicSubdomainMap[subdomain]?.questionnairePageHeading || getTextForLanguage(defaultQuestionnaire.questionnairePageHeading, language);
  const clinicSuccessPage = ClinicSubdomainMap[subdomain]?.successPage || defaultQuestionnaire.successPage;
  const clinicProducts = ClinicSubdomainMap[subdomain]?.products || defaultQuestionnaire.products;
  const clinicLogo = ClinicSubdomainMap[subdomain]?.logo || HeaderLogoSvg
  const clinicName = ClinicSubdomainMap[subdomain]?.name || DEFAULT_CLINIC_NAME
  const renderClinicProductResults = ClinicSubdomainMap[subdomain] ? ClinicSubdomainMap[subdomain]?.displayProductResults : true
  const displayIntroPage = ClinicSubdomainMap[subdomain]?.displayIntroPage || null;
  const showContactPageFirst = ClinicSubdomainMap[subdomain]?.showContactPageFirst || false;
  // functions
  const getClinicProductForPatient = useCallback(() => {
    const isVitallyHairClinic = clinicId === ClinicSubdomainMap['vitallyhair']?.id;

    if(!clinicProducts?.length || !isVitallyHairClinic) return;

    const shouldReturnActiivProducts = Math.ceil(Math.random() * 5) > 2;
    return shouldReturnActiivProducts ?
    ClinicSubdomainMap['vitallyhair']?.products[1] :
    ClinicSubdomainMap['vitallyhair']?.products[0]
  }, [clinicId, clinicProducts])
  
  const getResultMetaData = useCallback((classValue, hairThinningPercentage) => {
    const activeSubdomain = ClinicSubdomainMap[subdomain];
    if (activeSubdomain && activeSubdomain.getResultMetaData) {
      return activeSubdomain.getResultMetaData(classValue, hairThinningPercentage);
    }
    return null;
  }, [subdomain]);


  return {
    clinicId,
    clinicQuestionnaire,
    clinicSuccessPage,
    questionnairePageHeading,
    clinicProducts,
    clinicLogo,
    clinicName,
    renderClinicProductResults,
    getResultMetaData,
    displayIntroPage,
    getClinicProductForPatient,
    showContactPageFirst,
  }
}