import { FaceMesh } from "@mediapipe/face_mesh";
import React, { useRef, useEffect, useState } from "react";
import * as cam from "@mediapipe/camera_utils";
// import * as mediapipeUtils from "@mediapipe/drawing_utils";
import Webcam from "react-webcam";
import Modal from "react-modal";
// import CanvasDraw from "react-canvas-draw";
import place from "../assets/media/place_corner_v4.mp3";
import tilt from "../assets/media/tilt_head_v4.mp3";
import shutter from "../assets/media/camera-shutter-click_v3.mp3";
import timer from "../assets/media/hold_still_v2.mp3";
import done from "../assets/media/capture_done_v3.mp3";
// import useWindowSize from "use-window-size-v2";
import { Howl } from "howler";
import { Camera } from "react-camera-pro";
// import { Camera } from "react-html5-camera-photo";
import styled from "styled-components";
import { useTranslation } from "../contexts/translationContext";

let EYES_BRIDGE_LANDMARK_INDEX = 8;
let LEFT_EYE_LANDMARK_INDEX = 130;
let RIGHT_EYE_LANDMARK_INDEX = 359;
let LIPS_LANDMARK_INDEX = [61, 291];
var lips = [0.0];

let yellow = "#FFE17B";
let blue = "#52e6dd";
let red = "#D71313";
var bridgeColor = red;
var leftColor = red;
var rightColor = red;
var lipsColor = red;
// var place_corner = new Audio(place);
// var tilt_head = new Audio(tilt);
// var camera_sound = new Audio(shutter);
var sound = new Howl({
  src: [shutter],
});
var timer_sound = new Howl({
  src: [timer],
  html5: true,
});
var placeH = new Howl({
  src: [place],
  html5: true,
});
var tiltH = new Howl({
  src: [tilt],
  html5: true,
});
var capture_done = new Howl({
  src: [done],
  html5: true,
});
var first = 1;
const Button = styled.button`
  background-color: #229cba;
  font-size: 18px;
  color: #d8dfe4;
  border-radius: 12px;
  padding: 2px 10px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

// UIWebView.allowsInlineMediaPlayback = YES;
// UIWebView.mediaPlaybackRequiresUserAction = NO;

// var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
// var height = window.innerHeight > 0 ? window.innerHeight : screen.height;
function MlCaptureScreen({ captureImage, size, taken, flag }) {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const cameraRef = useRef(null);
  const imgCanvasRef = useRef(null);
  const [base64, setBase64] = useState("");
  const isAudioEnabled = useRef(false)
  const { translate } = useTranslation();
  // const [cameraSize, setCameraSize] = useState([480, 720]);
  const [modalIsOpen, setIsOpen] = useState(false);
  // const [image, setImage] = useState(false);
  const [show, setShow] = useState(false);
  const [isAudioPlayed, setIsAudioPlayed] = useState(false);
  // const [stream, setStream] = useState(false);
  const [ready, setReady] = useState(false);
  const val = useRef(40);
  const getScreenshot = useRef(false);
  var gotIt = false;
  const width = window.innerWidth;
  const height = window.innerHeight;

  const [showButton, setShowButton] = useState(true);
  const enableAudio = (e) => {
    isAudioEnabled.current = true
    timer_sound.on("unlock", function () {});
    sound.on("unlock", function () {});
    placeH.on("unlock", function () {});
    tiltH.on("unlock", function () {});
    capture_done.on("unlock", function () {});

    setShowButton(!showButton);
    setTimeout(function () {
      placeH.stop();
      placeH.play();
    }, 500);

    first = 2;
  };

  useEffect(() => {
    if(!isAudioPlayed) {
      setIsAudioPlayed(true);
      enableAudio();
    }
  },[])

  if (flag == 1) {
    setShowButton(!showButton);
  }

  // function openModal() {
  //   setIsOpen(true);
  // }

  function closeModal() {
    setIsOpen(false);
    lips = [0.0];
    gotIt = false;
    getScreenshot.current = false;
  }

  useEffect(() => {
    first = 1;
    const faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      },
    });

    faceMesh.setOptions({
      maxNumFaces: 1,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    faceMesh.onResults(onResults);

    // if (
    //   typeof webcamRef.current !== "undefined" &&
    //   webcamRef.current !== null
    // ) {
    camera = new cam.Camera(webcamRef.current.video, {
      onFrame: async () => {
        if (webcamRef?.current?.video) {
          await faceMesh.send({
            image: webcamRef?.current?.video,
          });
        }
      },
      width: width,
      height: width,
    });
    camera.start();

    // setCameraSize([
    //   webcamRef.current.props.width,
    //   webcamRef.current.props.height,
    // ]);
    // }

    if (taken) {
      setShow(true);
    } else {
      setShow(false);
    }
    if (!taken) {
      lips = [0.0];
      gotIt = false;
      // console.log("object", canvasRef.objectFit);
    }
    // window.location.reload();
  }, [taken, show]);

  // window.location.reload();

  console.log("size", size);
  // console.log("window w", width, "window h", height);
  let defaultWidth = 1080;
  let defaultHeight = 1920;
  let treshold = (width * 1.5) / 100;

  // const isLandscape = height <= width;
  // const ratio = isLandscape ? width / height : height / width;
  // const connect = mediapipeUtils.drawConnectors;
  var camera = null;

  function onResults(results) {
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    var width_cam_seen = (videoHeight * width) / height;
    var right_cam = (videoWidth - width_cam_seen) / 2;
    var left_cam = (videoWidth + width_cam_seen) / 2;
    var seen_width = right_cam - left_cam;

    // var width_location_ex = left_cam + seen_width / 3;

    // Set canvas width
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    // var hRatio = canvasElement.width / videoWidth;
    // var vRatio = canvasElement.height / videoHeight;
    // var ratio = Math.min(hRatio, vRatio);
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.translate(canvasElement.width, 1);
    canvasCtx.scale(-1, 1);
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );
    // canvasCtx.drawImage(
    //   results.image,
    //   cropX,
    //   cropY,
    //   right_cam,
    //   left_cam,
    //   0,
    //   0,
    //   canvasCtx.width,
    //   canvasCtx.height
    // );

    var right = [];
    var left = [];

    if (window.innerHeight > window.innerWidth) {
      // console.log("portrait");
      // right = [
      //   ((defaultWidth / 1.75) * canvasCtx.canvas.width) / defaultWidth,
      //   ((defaultHeight / 1.85) * canvasCtx.canvas.height) / defaultHeight,
      // ];
      // left = [
      //   ((defaultWidth / 2.35) * canvasCtx.canvas.width) / defaultWidth,
      //   ((defaultHeight / 1.85) * canvasCtx.canvas.height) / defaultHeight,
      // ];
      right = [
        seen_width / 3.5 + left_cam - (50 - val.current),
        canvasCtx.canvas.height / 2.25,
      ];
      left = [
        (2.5 * seen_width) / 3.5 + left_cam + (50 - val.current),
        canvasCtx.canvas.height / 2.25,
      ];
    } else {
      // console.log("landscape");
      right = [
        ((defaultWidth / 1.6) * canvasCtx.canvas.width) / defaultWidth -
          (50 - val.current),
        ((defaultHeight / 1.85) * canvasCtx.canvas.height) / defaultHeight,
      ];
      left = [
        ((defaultWidth / 2.6) * canvasCtx.canvas.width) / defaultWidth +
          (50 - val.current),
        ((defaultHeight / 1.85) * canvasCtx.canvas.height) / defaultHeight,
      ];
    }
    if (results.multiFaceLandmarks) {
      if (first === 1 && isAudioEnabled.current) {
        placeH.stop();
        placeH.play();
        first = 2;
      }
      // var widthCanva = width;
      // var heightCanva = height;
      canvasCtx.strokeStyle = bridgeColor;
      canvasCtx.moveTo(canvasCtx.canvas.width / 2, canvasCtx.canvas.height / 6);
      canvasCtx.lineTo(
        canvasCtx.canvas.width / 2,
        canvasCtx.canvas.height / 6 + (canvasCtx.canvas.height * 50) / 100
      );
      canvasCtx.lineWidth = (width * 0.5) / 100;
      canvasCtx.stroke();
      canvasCtx.strokeStyle = leftColor;
      canvasCtx.beginPath();
      canvasCtx.arc(left[0], left[1], treshold, 0, 2 * Math.PI);
      canvasCtx.stroke();
      canvasCtx.strokeStyle = rightColor;
      canvasCtx.beginPath();
      canvasCtx.arc(right[0], right[1], treshold, 0, 2 * Math.PI);
      canvasCtx.stroke();
      canvasCtx.strokeStyle = lipsColor;
      canvasCtx.beginPath();
      canvasCtx.moveTo(canvasCtx.canvas.width / 2 - seen_width / 4, lips[1]);
      canvasCtx.lineTo(canvasCtx.canvas.width / 2 + seen_width / 4, lips[1]);
      canvasCtx.stroke();
      for (const landmarks of results.multiFaceLandmarks) {
        canvasCtx.fillStyle = yellow;
        canvasCtx.beginPath();
        canvasCtx.arc(
          landmarks[EYES_BRIDGE_LANDMARK_INDEX].x * canvasCtx.canvas.width,
          landmarks[EYES_BRIDGE_LANDMARK_INDEX].y * canvasCtx.canvas.height,
          3,
          0,
          2 * Math.PI
        );
        canvasCtx.fill();
        canvasCtx.beginPath();
        canvasCtx.arc(
          landmarks[LEFT_EYE_LANDMARK_INDEX].x * canvasCtx.canvas.width,
          landmarks[LEFT_EYE_LANDMARK_INDEX].y * canvasCtx.canvas.height,
          3,
          0,
          2 * Math.PI
        );
        canvasCtx.fill();
        canvasCtx.beginPath();
        canvasCtx.arc(
          landmarks[RIGHT_EYE_LANDMARK_INDEX].x * canvasCtx.canvas.width,
          landmarks[RIGHT_EYE_LANDMARK_INDEX].y * canvasCtx.canvas.height,
          3,
          0,
          2 * Math.PI
        );
        canvasCtx.fill();

        if (
          landmarks[EYES_BRIDGE_LANDMARK_INDEX].x * canvasCtx.canvas.width -
            treshold <=
            canvasCtx.canvas.width / 2 &&
          landmarks[EYES_BRIDGE_LANDMARK_INDEX].x * canvasCtx.canvas.width +
            treshold >=
            canvasCtx.canvas.width / 2
          //   &&
          // landmarks[EYES_BRIDGE_LANDMARK_INDEX].y * canvasCtx.canvas.height -
          //   15 <=
          //   canvasCtx.canvas.height / 2 &&
          // landmarks[EYES_BRIDGE_LANDMARK_INDEX].y * canvasCtx.canvas.height +
          //   15 >=
          //   canvasCtx.canvas.height / 2
        ) {
          bridgeColor = blue;
        } else {
          bridgeColor = red;
        }
        if (
          landmarks[LEFT_EYE_LANDMARK_INDEX].x * canvasCtx.canvas.width -
            treshold <=
            left[0] &&
          landmarks[LEFT_EYE_LANDMARK_INDEX].x * canvasCtx.canvas.width +
            treshold >=
            left[0] &&
          landmarks[LEFT_EYE_LANDMARK_INDEX].y * canvasCtx.canvas.height -
            treshold <=
            left[1] &&
          landmarks[LEFT_EYE_LANDMARK_INDEX].y * canvasCtx.canvas.height +
            treshold >=
            left[1]
        ) {
          leftColor = blue;
        } else {
          leftColor = red;
        }
        if (
          landmarks[RIGHT_EYE_LANDMARK_INDEX].x * canvasCtx.canvas.width -
            treshold <=
            right[0] &&
          landmarks[RIGHT_EYE_LANDMARK_INDEX].x * canvasCtx.canvas.width +
            treshold >=
            right[0] &&
          landmarks[RIGHT_EYE_LANDMARK_INDEX].y * canvasCtx.canvas.height -
            treshold <=
            right[1] &&
          landmarks[RIGHT_EYE_LANDMARK_INDEX].y * canvasCtx.canvas.height +
            treshold >=
            right[1]
        ) {
          rightColor = blue;
        } else {
          rightColor = red;
        }

        if (bridgeColor == blue && leftColor == blue && rightColor == blue) {
          if (isAudioEnabled.current) {
            placeH.stop();
            if(!tiltH.playing()) {
              tiltH.stop();
              tiltH.play();
            }
          }
          gotIt = true;
          lips = [
            landmarks[LIPS_LANDMARK_INDEX[0]].x * canvasCtx.canvas.width,
            landmarks[LIPS_LANDMARK_INDEX[0]].y * canvasCtx.canvas.height,
          ];
        }
         if(gotIt) {
          if (
            landmarks[EYES_BRIDGE_LANDMARK_INDEX].y * canvasCtx.canvas.height -
              treshold * 2 <=
              lips[1] &&
            landmarks[EYES_BRIDGE_LANDMARK_INDEX].y * canvasCtx.canvas.height +
              treshold >=
              lips[1]
          ) {
            lipsColor = blue;

            if (getScreenshot.current === false) {
              tiltH.stop();
              if(isAudioEnabled.current) {
                setTimeout(function () {
                  timer_sound.stop();
                  timer_sound.play();
                }, 700);
              }
              getScreenshot.current = true;
              setReady(true);
              setTimeout(function () {
                  timer_sound.stop();
                  sound.stop();
                  sound.play();
                const img = cameraRef.current.takePhoto();
                // const img = webcamRef.current.getScreenshot();

                // const targetWidth = 360;
                // const targetHeight = 640;
                const image = new Image();
                image.src = img;

                image.onload = () => {
                  const canvas = document.createElement("canvas");
                  canvas.width = 1080; // Adjust to your desired portrait width
                  canvas.height = 1920; // Adjust to your desired portrait height

                  const ctx = canvas.getContext("2d");

                  const imageAspectRatio = image.width / image.height;
                  const canvasAspectRatio = canvas.width / canvas.height;

                  let cropWidth = image.width;
                  let cropHeight = image.height;
                  let cropX = 0;
                  let cropY = 0;

                  if (imageAspectRatio > canvasAspectRatio) {
                    // Image is wider than canvas, crop sides
                    cropWidth = image.height * canvasAspectRatio;
                    cropX = (image.width - cropWidth) / 2;
                  } else {
                    // Image is taller than canvas, crop top and bottom
                    cropHeight = image.width / canvasAspectRatio;
                    cropY = (image.height - cropHeight) / 2;
                  }

                  ctx.drawImage(
                    image,
                    cropX,
                    cropY,
                    cropWidth,
                    cropHeight,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                  );

                  // Mirror the image horizontally
                  ctx.scale(-1, 1);
                  ctx.drawImage(canvas, -canvas.width, 0);

                  // Convert the canvas content to a data URL (base64 encoded image)
                  const mirroredImageUrl = canvas.toDataURL("image/jpeg");

                  setBase64(mirroredImageUrl);
                  if (!taken) {
                    captureImage(mirroredImageUrl);
                  }
                };

                // openModal();
              }, 3000);
              if (first === 2) {
                if(isAudioEnabled.current) {
                  setTimeout(function () {
                    capture_done.stop();
                    capture_done.play();
                  }, 4000);
                }
                first = 0;
              }
            }
          } else {
            lipsColor = red;
          }
        }
      }
    }
    canvasCtx.restore();
  }

  // const videoConstraints = {
  //   width: 20000,
  //   height: 20000,
  //   // aspectRatio: 9 / 18,
  //   facingMode: "user",
  // };
  return (
    <div>
     {/* { showButton && <button
          type="button"
          className="box-border w-[70%] h-10 p-2 bg-background-secondary-1 rounded-3xl cursor-pointer flex items-center justify-center font-normal text-text-secondary-1 mx-auto mb-5 mt-3"
          onClick={enableAudio}
        >
          {translate('turn_on_voice_guidance')}
        </button> }   */}
    <div
      className="flex justify-center items-center"
      style={{ display: taken ? "none" : "block" }}
    >
      <div
        style={{
          width: 360, // Adjust to your desired portrait width
          height: 640, // Adjust to your desired portrait height
          overflow: "hidden",
          position: "relative",
        }}
        className="mx-auto overflow-hidden rounded-2xl"
      >
        {/* <Camera
          style={{
            position: "absolute",
          }}
          onCameraStart={(stream) => {
            console.log("stream", stream);
            setStream(stream);
          }}
        /> */}
        {/* <div className="mx-auto overflow-hidden rounded-2xl relative"  style={{
          width: 380, // Adjust to your desired portrait width
          height: 640, // Adjust to your desired portrait height
        }}> */}
        <Webcam
          // videoConstraints={videoConstraints}
          ref={webcamRef}
          // forceScreenshotSourceSize
          style={{
            position: "absolute",
            // marginLeft: "auto",
            // marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            // imageSmoothing: true,
            width: width,
            height: height,
            // width: '100%',
            // height: '100%',
            objectFit: "cover",
          }}
          screenshotFormat="image/jpeg"
          mirrored={true}
          // height="140"
          // width="160"
          height={height}
          width={width}
        />{" "}
        <canvas
          ref={canvasRef}
          className="output_canvas"
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            // left: 0,
            // right: 0,
            textAlign: "center",
            zindex: 0,
            width: 360,
            height: height,
            objectFit: "cover",
          }}
        ></canvas>
        {ready ? (
          <Camera
            ref={cameraRef}
            style={{
              position: "relative",
              width: width,
              height: height,
            }}
            facingMode="user"
            aspectRatio={9 / 16}
            idealResolution={{ width: 2560, height: 2560 }}
            isMaxResolution={true}
          />
        ) : (
          <></>
        )}
        <canvas ref={imgCanvasRef} style={{ display: "hidden" }}></canvas>
                
        <Modal
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          // style={customStyles}
          contentLabel="Example Modal"
        >
          <h2>You did it!</h2>
          <button onClick={closeModal}>close</button>
          <div>image ready</div>
          {/* <form>
            <input /> */}
          <a href={base64} target="_blank">
            open
          </a>
          {/* <button>stays</button>
            <button>inside</button>
          <button>the modal</button> */}
          {/* </form> */}
        </Modal>
      </div>
    </div>

     </div>
  );
}

export default MlCaptureScreen;
