import { PRODUCTS } from "../constants/products"

export const questionnaire = Object.freeze({
  "questionnairePageHeading": {
    "en": "Personalization",
    "es": "Llene los detalles"
  },
  "questionnaire": {
    "section1": {
      "title": {
        "en": "Complete this to receive your personal hair analysis & recommendations",
        "es": "Complete esto para recibir su análisis y recomendación personal del cabello"
      }
    },
    "section2": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "type": "radio",
      "question": {
        "en": "When did you first notice hair loss?",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "options": {
        "justNow": {
          "text": {
            "en": "Just now",
            "es": "En este momento"
          }
        },
        "threeMonthsAgo": {
          "text": {
            "en": "3 months ago",
            "es": "hace 3 meses"
          }
        },
        "sixMonthsAgo": {
          "text": {
            "en": "6 months ago",
            "es": "hace 6 meses"
          }
        },
        "oneYearAgo": {
          "text": {
            "en": "1 year ago",
            "es": "hace 1 año"
          }
        },
        "threeYearsAgo": {
          "text": {
            "en": "3 years ago",
            "es": "hace 3 años"
          }
        },
        "overFiveYears": {
          "text": {
            "en": "Over 5 years",
            "es": "Más de 5 años"
          }
        }
      }
    },
    "section3": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "type": "radio",
      "question": {
        "en": "Do any of these family members suffer from hair loss?",
        "es": "¿Alguno de estos miembros de la familia sufre pérdida de cabello?"
      },
      "options": {
        "father": {
          "text": {
            "en": "Father",
            "es": "Padre"
          }
        },
        "mother": {
          "text": {
            "en": "Mother",
            "es": "Madre"
          }
        },
        "sibling": {
          "text": {
            "en": "Siblings",
            "es": "Hermanos"
          }
        }
      }
    },
    "section4": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "question": {
        "en": "How concerned are you about your hair loss?",
        "es": "¿Qué tan preocupado estás por la caída del cabello?"
      },
      "type": "radio",
      "options": {
        "slight": {
          "text": {
            "en": "Slightly concerned",
            "es": "Ligeramente preocupado"
          }
        },
        "veryconcerned": {
          "text": {
            "en": "Very concerned",
            "es": "Muy preocupado"
          }
        },
        "dosomething": {
          "text": {
            "en": "I need to do something about it right now.",
            "es": "Necesito hacer algo al respecto ahora mismo."
          }
        }
      }
    },
    "section5": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "question": {
        "en": "Have you received any hair loss treatment for your hair loss in the past? If yes, please list treatments.",
        "es": "¿Ha recibido algún tratamiento anticaída para la caída del cabello en el pasado? En caso afirmativo, indique los tratamientos."
      },
      "type": "radioandtext",
      "options": {
        "yes": {
          "text": {
            "en": "Yes",
            "es": "Sí"
          }
        },
        "no": {
          "text": {
            "en": "No",
            "es": "No"
          }
        }
      },
      "textValue": ""
    },
    "section6": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "question": {
        "en": "Are you receiving any hair loss treatments now? If yes, please list treatments.",
        "es": "¿Estás recibiendo algún tratamiento para la caída del cabello ahora? En caso afirmativo, indique los tratamientos."
      },
      "type": "radioandtext",
      "options": {
        "yes": {
          "text": {
            "en": "Yes",
            "es": "Sí"
          }
        },
        "no": {
          "text": {
            "en": "No",
            "es": "No"
          }
        }
      },
      "textValue": ""
    },
    "section7": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "question": {
        "en": "Have you tried any hair loss products in the past? If yes, please list products.",
        "es": "¿Has probado algún producto para la caída del cabello en el pasado? En caso afirmativo, indique los productos."
      },
      "type": "radioandtext",
      "options": {
        "yes": {
          "text": {
            "en": "Yes",
            "es": "Sí"
          }
        },
        "no": {
          "text": {
            "en": "No",
            "es": "No"
          }
        }
      },
      "textValue": ""
    },
    "section8": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "question": {
        "en": "Are you using any products now? If yes, please list products.",
        "es": "¿Estás usando algún producto ahora? En caso afirmativo, indique los productos."
      },
      "type": "radioandtext",
      "options": {
        "yes": {
          "text": {
            "en": "Yes",
            "es": "Sí"
          }
        },
        "no": {
          "text": {
            "en": "No",
            "es": "No"
          }
        }
      },
      "textValue": ""
    },
    "section9": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "question": {
        "en": "What is your personal experience with the hair loss treatments and products you tried?",
        "es": "¿Cuál es tu experiencia personal con los tratamientos y productos anticaída que probaste?"
      },
      "type": "radio",
      "options": {
        "great": {
          "text": {
            "en": "Great",
            "es": "Excelente"
          }
        },
        "average": {
          "text": {
            "en": "Average",
            "es": "Promedio"
          }
        },
        "poor": {
          "text": {
            "en": "Poor",
            "es": "Pobre"
          }
        }
      }
    },
    "section10": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "question": {
        "en": "In your personal experience, what are the challenges you have when choosing a doctor or a hair loss product for your hair loss?",
        "es": "En tu experiencia personal, ¿cuáles son los desafíos que tienes al elegir un médico o un producto anticaída para tu caída del cabello?"
      },
      "type": "radio",
      "options": {
        "iDontKnow": {
          "text": {
            "en": "I don't know where to look for",
            "es": "no sé dónde buscar"
          }
        },
        "notEnoughInfo": {
          "text": {
            "en": "There are not enough information, data on the products",
            "es": "No hay suficiente información, datos sobre los productos."
          }
        },
        "dontknowifworking": {
          "text": {
            "en": "I can't tell if the treatment or product working for me while I am using it.",
            "es": "No puedo saber si el tratamiento o producto me funciona mientras lo uso."
          }
        }
      },
      "textValue": ""
    },
    "section11": {
      "title": {
        "en": "A few quick details to supplement your AI\u2011powered analysis",
        "es": "Algunos detalles breves para complementar su análisis desarrollado por IA"
      },
      "question": {
        "en": "Would you buy a product if you can track the results and see if it is working for you?",
        "es": "¿Compraría un producto si pudiera realizar un seguimiento de los resultados y ver si funciona para usted?"
      },
      "type": "radio",
      "options": {
        "yes": {
          "text": {
            "en": "Yes",
            "es": "Sí"
          }
        },
        "no": {
          "text": {
            "en": "No",
            "es": "No"
          }
        }
      },
      "textValue": ""
    }
  },
  "successPage": {
    "heading": {
      "en": "Thank you!",
      "es": "¡Gracias!"
    },
    "description": {
      "en": "Your image & information has been uploaded. Your results will be sent to you shortly.",
      "es": "Su imagen e información han sido cargadas. Sus resultados le serán enviados en breve."
    }
  }, 
  "products": [
    PRODUCTS.HAIRMAX,
    PRODUCTS.ZENAGEN,
    PRODUCTS.ACTIIV,
  ]
}
)