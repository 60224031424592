import React, { useEffect } from 'react'
import { ReactComponent as ArrowDown } from "../assets/images/ArrowDown.svg"
import SimpleButton from "./SimpleButton";
import { useTranslation } from '../contexts/translationContext';
import LanguageSelector from './LanguageSelector';

const IntroPage = ({setStep}) => {
  const { translate } = useTranslation();
  return (
    <div className="w-[75%] m-auto">
    <div className="w-full">
      <div
        className="mx-auto w-full"
        style={{ maxWidth: "600px"}}
      >
        <div className="text-center mt-5">
          <p className="text-text-3 font-font-3 font-medium text-xl">{translate('begin_your_hair_transformation_in_90_seconds')}</p>
        </div>

        <div className="text-center mt-4">
           <p className="font-font-3 font-bold text-md uppercase text-[#B7E5E4]">{translate('step1')}:</p>
           <p className="text-text-3 font-font-3 font-normal">{translate('provide_contact_details_for_results')} </p>
        </div>

        <div className="text-center mt-4">
           <p className="font-font-3 font-bold text-md uppercase text-[#B7E5E4]">{translate('step2')}:</p>
           <p className="text-text-3 font-font-3 font-normal">{translate('facial_recognition_ai_powered_hair_selfie_for_analysis')} </p>
        </div>

        <div className="text-center mt-4">
           <p className="font-font-3 font-bold text-md uppercase text-[#B7E5E4]">{translate('step3')}:</p>
           <p className="text-text-3 font-font-3 font-normal">{translate('personalization_qa')}</p>
        </div>

      </div>
      <div className="flex justify-center flex-col items-center relative mt-6 left-0 w-full" style={{ height:'calc(100% - 200px)' }}>
         <ArrowDown className="mb-9"/>
        <SimpleButton text={translate('get_started')} onClick={() => setStep(2)}/>
        <LanguageSelector />
      </div>
    </div>
  </div>
  )
}

export default IntroPage