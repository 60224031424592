import React from 'react';
import { useTranslation } from '../contexts/translationContext';

function LanguageSelector() {
  const { changeLanguage, language } = useTranslation();

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  return (
    <div className="inline-block -mt-3">
      <div className='relative'>        
      <select
        onChange={handleChangeLanguage}
        value={language}
        className="block appearance-none w-full bg-transparent text-white underline font-normal text-sm px-4 py-1 pr-6 rounded shadow leading-tight outline-none cursor-pointer"
      >
        <option value="en">English</option>
        <option value="es">Spanish</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M10 12.586l4.293-4.293a1 1 0 0 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L10 12.586z"/>
        </svg>
      </div>
      </div>
    </div>
  );
}

export default LanguageSelector;
