import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as ArrowDown } from "../assets/images/ArrowDown.svg";
import { ReactComponent as SelfieGirl } from "../assets/images/SelfieGirl.svg";

import { HeaderLogo } from "./HeaderLogo";
import Spinner from "./Spinner";
import SimpleButton from "./SimpleButton";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "../contexts/translationContext";
import { useClinic } from "../hooks/useClinic";
import IntroPage from "./IntroPage";
import { useRouter } from "../hooks/useRouter";
import ContactDetails from "./ContactDetails";

export const Home = ({ formData, setFormData }) => {
  const { displayIntroPage, showContactPageFirst } = useClinic();
  const [step, setStep] = useState(1);
  const { translate } = useTranslation();

  useEffect(() => {
    if (displayIntroPage) {
      setStep(0);
    }
    // if subdomain map has displayIntroPage true. then it will show the intropage.
    // currently it will show same intropage for all, if we need different intropage for each subdomain we can modify Intropage Component.
  }, [displayIntroPage]);

  useEffect(() => {
    navigator.permissions
      .query({ name: "camera" })
      .then((permissionObj) => {
        console.log(permissionObj.state);
        // permission = permissionObj.state;
      })
      .catch((error) => {
        console.log("Got error :", error);
      });
  }, []);

  const tutorial1Steps = [
    translate("wet_your_hair"),
    translate("part_your_hair_in_middle"),
    translate("comb_and_flatten_hair"),
  ];

  const tutorial2Steps = [
    translate("hold_the_phone_in_front_of_you"),
    translate("raise_the_phone_above_your_eyes"),
    translate("follow_the_voice_guidance"),
  ];
  const videos = [
    "https://grotrack.online/videos/how_to_prepare_hair_for_hair_selfie.mp4",
    "https://grotrack.online/videos/how_to_take_hair_selfie.mp4",
  ];

  return (
    <div className="centered absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] overflow-auto h-auto w-full">
      <HeaderLogo showCrossButton={true} step={step} setStep={setStep} />
      {step === 0 && displayIntroPage && <IntroPage setStep={() => setStep(showContactPageFirst ? 'contact' : 2)}/>}
      {step === 'contact' && showContactPageFirst && <ContactDetails setStep={setStep} formData={formData} setFormData={setFormData}/>}
      {step === 1 && <Capture setStep={setStep} showContactPageFirst={showContactPageFirst}/>}
      {(step === 2 || step === 3) && (
        <div
          className="w-full mx-auto flex justify-center font-font-2"
          style={{ maxWidth: "600px" }}
        >
          {/* {step === 2 && (
            <Tutorial
              actionText={translate('imready')}
              heading={translate('how_to_prepare')}
              steps={tutorial1Steps}
              vid={videos[0]}
              setStep={setStep}
              step={2}
            />
          )} */}
          {step === 2 && (
            <Tutorial
              actionText={translate("letsgo")}
              heading={translate("how_to_take_hair_selfie")}
              steps={tutorial2Steps}
              vid={videos[1]}
              setStep={setStep}
              step={3}
            />
          )}
        </div>
      )}
    </div>
  );
};

const Capture = ({ setStep, showContactPageFirst }) => {
  const location = useLocation();
  const { translate } = useTranslation();
  return (
    <div>
      <div className="w-full">
        <div className="mx-auto w-full" style={{ maxWidth: "600px" }}>
          <div className="text-center mt-4">
            <p className="text-text-3 font-font-3 font-semibold text-xl">
              {translate("hair_selfie")}
            </p>
            {/* <p className="text-text-primary-1 font-font-3 font-normal text-base">Thinning Hair Assessment</p> */}
            <p className="text-text-primary-1 font-font-3 font-thin text-sm">
              {translate("powered_by_ai")}
            </p>
          </div>
          {/* {location?.state?.img && (
            <div className="m-auto mt-4">
              <img
                src={location.state.img}
                className="mx-auto"
                alt="captured resource"
              />
              <p className="text-text-2 m-auto w-fit mt-4">
                Image is being processed
              </p>
            </div>
          )} */}
        </div>
        <div
          className="flex justify-center flex-col items-center relative mt-6 left-0 w-full"
          style={{ height: "calc(100% - 200px)" }}
        >
          <SelfieGirl className="h-full mx-auto" />
          <p className="text-text-3 font-light text-center px-16 mb-2 font-font-3 mt-6">
            {translate("start_desc")}
          </p>
          <ArrowDown className="mb-9" />
          <SimpleButton text={translate("start")} onClick={() => setStep(showContactPageFirst ? 'contact': 2)} />
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};

const Tutorial = ({ actionText, heading, steps, vid, setStep, step }) => {
  const navigate = useNavigate();
  const { translate } = useTranslation();

  const { routeWithParams } = useRouter();

  const handleStep = () => {
    if (step === 3) {
      navigate(routeWithParams("/capture"));
    }
    setStep(3);
  };
  return (
    <div className="w-full">
      <div className="text-center mt-4">
        <p className="text-text-3 font-font-3 font-semibold text-xl">
          {translate("hair_selfie")}
        </p>
        <p className="text-text-primary-1 font-font-3 font-thin text-sm">
          {translate("powered_by_ai")}
        </p>
      </div>
      <div className="w-full flex justify-center items-center h-[390px]">
        <div className="absolute left-1/2 transform translate-x-[-50%]">
          <Spinner />
        </div>
        <video
          className="w-[80%] md:w-1/2 rounded-2xl z-10"
          autoPlay
          muted
          playsInline
        >
          <source src={vid} type="video/mp4" />
        </video>
      </div>
      <div className="w-fit m-auto text-gray-300">
        <p className="text-text-3 text-center font-font-3 font-semibold text-xl">
          {heading}
        </p>
        <div className="text-gray-400 font-medium">
          <ul className="list-disc list-inside text-center">
            {steps.map((step, index) => {
              return (
                <li
                  key={step}
                  className="text-text-primary-1 font-font-3 font-thin text-base"
                >
                  {`${step}`}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="text-text-3 font-font-3 font-normal text-center w-3/4 m-auto mt-4">
          For best results, part your hair in the middle and comb it behind your
          ears, or in a ponytail.
        </div>
        <div className="flex justify-center mt-12">
          <SimpleButton text={actionText} onClick={handleStep} />
        </div>
      </div>
    </div>
  );
};
