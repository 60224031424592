import React from 'react'
import { ReactComponent as BackSvg } from '../assets/images/BackNavArrow.svg'
import { ReactComponent as Cross } from '../assets/images/cross.svg'
const TopBarNavigation = ({text, onPrevClick, onCloseClick}) => {
  return (
    <div className='w-full bg-[#B7E5E4] py-3 px-5 flex justify-between items-center text-xl font-light text-text-secondary-1'>
        <BackSvg onClick={onPrevClick}/>
        <p>{text}</p>
        <Cross onClick={onCloseClick}/>
    </div>
  )
}

export default TopBarNavigation