import { RxCross2 } from 'react-icons/rx';
import { useClinic } from '../hooks/useClinic';

export const HeaderLogo = ({ showCrossButton = false, setStep, step }) => {
    const {clinicLogo, displayIntroPage} = useClinic()
    const handleStepChange = () => {
        let newStep;
        if (displayIntroPage) {
            newStep = step - 1 === 1 ? 0 : step - 1;
        } else {
            newStep = step - 1;
        }
        setStep(newStep);
    };
    return (
        <div className='flex justify-center py-4'>
            {(showCrossButton && (step !== 1 && step !== 0 && step !== 'contact')) &&
                <div className='absolute left-0 h-12'>
                    <RxCross2 onClick={handleStepChange} className='w-8 h-8 mx-2 mt-1 text-text-2 cursor-pointer' />
                </div>
            }
            <img className='h-16' src={clinicLogo} alt='Logo' />
        </div>
    )
}