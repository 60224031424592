import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";

import { HeaderLogo } from "./HeaderLogo";
import MlCaptureScreen from "./MlCaptureScreen";
import { useTranslation } from "../contexts/translationContext";
import { useRouter } from "../hooks/useRouter";
import { trackEvent } from "../utils/googleAnalytics";

export const CaptureScreen = () => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { routeWithParams } = useRouter();

  const [imgSrc, setImgSrc] = useState("");
  const [show, setShow] = useState(true);
  const [taken, setTaken] = useState(false);
  const [flag, setFlag] = useState(0);
  const ref = useRef(null);

  const captureImage = (img) => {
    setImgSrc(img);
    setTaken(true);
    setFlag(1);
  };

  const uploadImage = () => {
    trackEvent('button_click', { button_name: 'Image Captured', action: 'Clicked' });
    navigate(routeWithParams("/upload"), { state: { img: imgSrc } });
  };
  var size = [];
  useEffect(() => {
    size = [1000, 1000];
    if (imgSrc == null) {
      setTaken(false);
    }
  }, [size]);

  const retakeImage = () => {
    navigate(routeWithParams("/capture"), {
      replace: false,
      state: {
        imgSrc: setImgSrc(""),
        taken: setTaken(false),
        flag: setFlag(0),
        size: size,
      },
    });
  };

  return (
    <>
      <HeaderLogo />
      <div
        className="w-full mx-auto px-2 font-font-2 font-medium text-lg text-gray-400"
        ref={ref}
      >
        {imgSrc && (
          <div className="text-center mb-4">
            <p className="text-text-3 font-font-3 font-semibold text-xl text-center">
              {translate("image_captured_successfully")}
            </p>
          </div>
        )}
        {imgSrc ? (
          <div className="mx-auto" style={{ maxWidth: "600px" }}>
            <div className="mx-auto w-[360px] overflow-hidden rounded-2xl">
              <img
                className="w-[100%] mx-auto"
                src={imgSrc}
                style={{ display: taken ? "block" : "none" }}
                alt="captued resource"
              />
            </div>

            <div className="flex justify-center -mt-12  w-[330px] mx-auto gap-4">
              <button
                type="button"
                className="box-border w-full h-11 relative bottom-6 p-3 bg-[#B7E5E4] rounded-3xl cursor-pointer flex items-center justify-center font-normal text-text-secondary-1"
                onClick={uploadImage}
              >
                {translate("upload")}
              </button>

              <button
                type="button"
                className="box-border w-full h-11 relative bottom-6 p-3 bg-background-secondary-1 rounded-3xl cursor-pointer flex items-center justify-center font-normal text-text-secondary-1"
                onClick={retakeImage}
              >
                {translate("retake")}
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-center w-full">
              <MlCaptureScreen
                captureImage={captureImage}
                size={size}
                taken={taken}
                flag={flag}
              />
            </div>
            <p className="mt-6 text-text-primary-1 font-font-3 font-thin text-base text-center px-4 pb-4">
              {translate("slowly_tilt_your_head_down")}
            </p>
          </>
        )}
      </div>
    </>
  );
};
