import { useState, useEffect } from "react";
import { HeaderLogo } from "./HeaderLogo";
import SimpleButton from "./SimpleButton";
import { useClinic } from "../hooks/useClinic";
import { useTranslation } from "../contexts/translationContext.js"
import { getTextForLanguage } from "../utils/index.js"
const SuccessPage = () => {
  const { clinicSuccessPage } = useClinic();
  const { language, translate } = useTranslation();
  return (
    <div className="centered absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] overflow-auto h-auto w-full text-center">
      <HeaderLogo />
      <div className="textcontainer w-[90%] m-auto min-h-[40vh] flex flex-col items-center justify-center">
        <div className="text-4xl font-bold text-white mb-5 opacity-70">{getTextForLanguage(clinicSuccessPage?.heading, language)}</div>
        <div
          className="text-text-3 font-font-3 text-base font-normal w-[92%] m-auto mt-1 mb-2"
          dangerouslySetInnerHTML={{ __html: getTextForLanguage(clinicSuccessPage?.description, language)}}
        ></div>
        <SimpleButton
          text={translate("back_to_home")}
          onClick={() => (window.location.href = clinicSuccessPage?.link || "/")}
          style={{ marginTop: "100px" }}
        />
      </div>
    </div>
  );
};

export default SuccessPage;
