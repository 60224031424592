import { useLocation } from "react-router-dom";

// constants
import { REFERRER_PARAM_KEY } from "../constants";
import { useCallback } from "react";

export const useRouter = () => {
  // get params from URL
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const referrer = searchParams.get(REFERRER_PARAM_KEY);

  const routeWithParams = useCallback(
    (route) => {
      const searchParams = new URLSearchParams(search);

      return route + "?" + searchParams?.toString();
    },
    [search]
  );

  return {
    routeWithParams,
    referrer,
  };
};
